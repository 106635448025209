.topTip {
  width: 100%;
  height: 40px;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 400 14px var(--font-family);
  overflow: hidden;

  .left {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #0e1327;
  }

  .closeBtn {
    position: absolute;
    right: 16px;
    z-index: 2;
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.14);
    text-align: center;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    .closeIcon {
      width: 12px;
      height: 12px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.25);
    }
  }
}

@media only screen and (max-width: 640px) {
  .topTip {
    justify-content: flex-start;
    height: 60px;

    .left {
      background-color: #0e1327;
    }
  }
}

@media only screen and (max-width: 320px) {
  .topTip {
    .centerImg {
      width: 231px;
      height: 41px;
      background-size: 231px 41px;
    }
  }
}
