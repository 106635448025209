@import url("../../../../../global.module.less");

.features_card {
  padding: 4rem;
  border-radius: var(--radius-24);
  box-shadow: var(--shadow-3);

  .card_icon {
    overflow: hidden;
    border-radius: 14px;
    width: 46px;
    height: 46px;
    margin-bottom: var(--spacing-16);
  }

  .left {
    h2 {
      font: var(--Paragraph-1);
      color: #009dff;
      opacity: 1 !important;
      font-weight: 600;
    }

    h3 {
      font: var(--Heading-2);
    }
  }
}

.features_cards-wrap_btm {
  width: 100%;
  display: flex;
  margin-top: var(--spacing-24);
}

.features_cards-wrap_btm_left {
  width: 50%;
  margin-right: 12px;

  h2 {
    font: var(--Paragraph-1);
    color: #009dff;
    opacity: 1 !important;
    font-weight: 600;
  }
}

.features_cards-wrap_btm_right {
  width: 50%;
  margin-left: 12px;

  .feature-resource {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #39bcf9;
    height: calc(50% - 12px);
    padding: 4.57rem !important;
    overflow: hidden;
    position: relative;

    .design-resource {
      max-width: 100% !important;
    }

    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .left {
        h2 {
          color: var(--white);
          margin-bottom: var(--spacing-8);
        }

        p {
          font: var(--Heading-2);
          color: white;
          max-width: 55%;
        }
      }
    }

    .btm {
      .resource-card-wrap {
        position: absolute;
        bottom: 3.428rem;
        // padding-left: 60px;
        display: flex;

        img {
          transition: all 250ms cubic-bezier(0.05, 0.03, 0.35, 1) 0s;
          border-radius: 1rem;
          width: 100%;
          object-fit: cover;
        }

        a {
          z-index: 1;
          width: 100%;
        }

        & > div {
          position: relative;
          width: 270px;
          height: 175px;
          margin: 0 8px;
          transition: var(--transition-easeOutBack-1);
          display: flex;
          justify-content: center;
          border-radius: 1rem;
          overflow: hidden;
          box-shadow: var(--shadow-4);

          &:first-child {
            margin-left: 0;
          }

          span {
            display: block;
            position: absolute;
            width: 107%;
            height: 126%;
            background-color: var(--white);
            border-radius: 24px;
            box-shadow: 0 2px 12px #eee;
            z-index: 0;
            top: -12px;
          }

          &:hover {
            z-index: 1;
            transform: scale3d(1.06, 1.06, 1.06);
            margin: 0 12px;
          }
        }
      }
    }

    .statistic-wrap {
      display: flex;
      position: absolute;
      right: 4.57rem;
      top: 100px;

      @media screen and (max-width: 640px) {
        left: 24px;
        top: 35% !important;
      }
    }
  }

  .feature-safe {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: #18232f;
    margin-top: 24px;
    height: calc(50% - 12px);
    overflow: hidden;
    padding: 0 !important;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 4.57rem;
    }

    ul {
      li {
        color: rgba(255, 255, 255, 0.8);
        margin-top: var(--spacing-8);
        font: var(--Paragraph-16);
        display: flex;
        align-items: center;

        & > div {
          margin-right: var(--spacing-8);
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .right {
      z-index: -1;
      position: absolute;
      display: flex;
      align-items: center;
      right: -4px;
      width: 55%;
      height: 100%;
      padding: 20px;
      top: 0;

      .safe-card_cover {
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
      }
    }

    h2 {
      color: #fff !important;
      margin-bottom: var(--spacing-8);
    }

    p {
      color: var(--white);
      font: var(--Heading-2);
      max-width: 260px;
    }
  }
}

@media screen and (max-width: 640px) {
  .features_cards-wrap_btm {
    flex-direction: column;
    margin-top: 18px !important;

    & > div {
      width: 100% !important;
    }
  }

  .features_cards-wrap_btm_right {
    margin-left: 0 !important;
    margin-top: 18px !important;

    .feature-resource {
      height: 440px;
      padding: 32px !important;

      .btm {
        height: 175px;
        width: 100%;
        position: absolute;
        bottom: 32px;
      }

      .left {
        width: 100%;
      }

      .left p {
        max-width: 100%;
      }

      .btm {
        .resource-card-wrap {
          /* padding-left: 24px !important; */
          bottom: 0 !important;
          left: 0;
          padding-left: 24px;
        }
      }
    }

    .feature-safe {
      height: 340px !important;
      margin-top: 18px !important;

      li {
        margin-top: var(--spacing-16) !important;
      }

      .left {
        padding: 32px !important;
      }

      .right {
        right: 5px;
        height: 123%;
        top: 3% !important;
      }
    }
  }
}
