// // @fontFamily: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
// //   Ubuntu, Cantarell, Fira Sans, Helvetica Neue, "Microsoft YaHei",
// //   "Microsoft JhengHei", "Source Han Sans SC", "Source Han Sans CN", sans-serif;

// // @font-family: @fontFamily;
// // // 默认shadow
// // @boxShadow: rgba(0, 0, 0, 0.08) 0px 4px 6px 0px,
// //   rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

// // // hover时shadow
// // @boxShadowHover: rgba(0, 0, 0, 0.11) 0px 6px 8px 0px,
// //   rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

// // @maxWidth: 1344px;

// // @spacing-base: 0.28571429rem;

// // @transition: all 200ms linear;

// // @boderRadius: 0.85714286rem;

// // @radius-base-4: 4px;

// // html,
// // body {
// //   overflow-y: auto;
// //   overflow-x: hidden;
// //   font-size: 14px;
// //   margin: 0 auto;
// //   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// // }
// // a:focus,
// // input:focus,
// // p:focus,
// // div:focus {
// //   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// //   -webkit-user-modify: read-write-plaintext-only;
// // }

// // @color-shadow-light: rgba(0, 0, 0, 0.15);
// // @color-shadow-dark: rgba(0, 0, 0, 0.6);
// // /* @@@@@- View @@@@@@ */
// // @view-1: 1440px;
// // @view-2: 1100px;
// // /* @@@@@- Color @@@@@@ */
// // @white: #ffffff;
// // @white-1: rgba(255, 255, 255, 0.5);
// // @white-2: rgba(255, 255, 255, 0.1);
// // @oceanblue-900: #000843;
// // @oceanblue-800: #000d44;
// // @oceanblue-700: #021b52;
// // @oceanblue-600: #012760;
// // @oceanblue-500: #002e68;
// // @oceanblue-400: #00467a;
// // @oceanblue-300: #015e8a;
// // @oceanblue-200: #0289ac;
// // @oceanblue-100: #7cb9cf;
// // /* @oceanblue-50: rgba(201, 227, 236, 1); */
// // @oceanblue-50: hsl(202, 100%, 95%);
// // /* @black-900: rgba(37, 37, 38, 1); */
// // @black-900: hsl(216, 30%, 20%);
// // /* @black-800: rgba(70, 70, 72, 1); */
// // @black-800: hsl(220, 12%, 16%);
// // @black-700: #666667;
// // @black-600: #7a7a7b;
// // @black-500: #a3a3a5;
// // @black-400: #c2c2c3;
// // @black-300: #e4e4e6;
// // @black-200: #f1f1f2;
// // /* @black-100: rgb(239, 239, 239); */
// // @black-100: hsl(216, 14%, 92%);
// // /* @black-50: rgb(245, 246, 246); */
// // @black-50: hsl(210, 51%, 97%);
// // @coffee-900: #5d3832;
// // @coffee-800: #6e463e;
// // @coffee-700: #7e5348;
// // @coffee-600: #8f6052;
// // @coffee-500: #9c6a59;
// // @coffee-400: #ae8174;
// // @coffee-300: #c0988c;
// // @coffee-200: #d6b5ae;
// // @coffee-100: #ecd2ca;
// // @coffee-50: #feece3;
// // @spring-green-900: #42705d;
// // @spring-green-800: #508f78;
// // @spring-green-700: #57a087;
// // @spring-green-600: #5fb098;
// // @spring-green-500: #66bfa5;
// // @spring-green-400: #70cab2;
// // @spring-green-300: #85d6c0;
// // @spring-green-200: #a8e3d2;
// // @spring-green-100: #c9ece3;
// // @spring-green-50: hsl(170, 48%, 86%);
// // @indigo-900: #133891;
// // @indigo-800: #234aa6;
// // @indigo-700: #2c54b2;
// // @indigo-600: #375fbe;
// // @indigo-500: #3d67ca;
// // @indigo-400: #617fd0;
// // @indigo-300: #7f98d7;
// // @indigo-200: #a5b7e2;
// // @indigo-100: #c9d2ec;
// // @indigo-50: #ecedf7;
// // @purple-900: #2c2b9f;
// // @purple-800: #4238ad;
// // @purple-700: #4f3db7;
// // @purple-600: #5e45bf;
// // @purple-500: #664ac6;
// // @purple-400: #7e64ce;
// // @purple-300: #9580d8;
// // @purple-200: #b3a7e1;
// // @purple-100: #d3caec;
// // @purple-50: #eee9f7;
// // @orchid-900: #7f248e;
// // @orchid-800: #9b2f9b;
// // @orchid-700: #ab35a3;
// // @orchid-600: #bd3eaa;
// // @orchid-500: #cb42b1;
// // @orchid-400: #d55dba;
// // @orchid-300: #dc7cc6;
// // @orchid-200: #e3a4d5;
// // @orchid-100: #edcae4;
// // @orchid-50: #f7ebf4;
// // @button-blue-1: hsl(203, 100%, 50%);
// // @button-blue-2: hsl(206, 100%, 49%);
// // @button-blue-3: hsl(203, 100%, 75%);
// // @popup-mask-bg: rgba(0, 0, 0, 0.13);
// // /* @@@@@ workspace @@@@@@-*/
// // @workspace-dark-bgColor-1: #303136;
// // @workspace-light-bgColor-1: #fff;
// // @workspace-dark-border-1: rgba(255, 255, 255, 0.1);
// // @workspace-light-border-1: #e8e8e8;
// // @workspace-light-color-1: #202020;
// // /* @@@@@ ai-muse AI 灵感 @@@@@@-*/
// // @ai-muses-page-background-color: #0f0f0f;
// // @ai-muses-button-blue-color: #2b84ff;
// // @ai-muses-button-blue-color-40: (43, 132, 255, 0.4);
// // @ai-muses-button-blue-hover-color: #2777e5;
// // @ai-muses-text-blue-color: #4a92ff;
// // @ai-muses-blue-100: #2c323b;
// // @ai-muses-blue-80: rgba(44, 50, 59, 0.8);
// // /* @@@@@- Spacing @@@@@@ */
// // @button-primary: #009dff;
// // @button-primary-hover: #008efa;
// // @button-primary-disabled: #009dff;
// // /* @@@@@- Spacing @@@@@@ */
// // @input-normal: 1px solid #dcdee6;
// // @input-hover-border: 1px solid #abacb3;
// // @input-focus-border: 2px solid #008efa;
// // @input-focus-bgColor: #f5faff;
// // @input-placeholder-fontColor: rgba(34, 34, 34, 0.4);
// // /* @@@@@- Spacing @@@@@@ */
// // @spacing-2: calc(@spacing-base / 2);
// // @spacing-base: 0.28571429rem;
// // @spacing-8: calc(@spacing-base * 2);
// // @spacing-12: calc(@spacing-base * 3);
// // @spacing-16: calc(@spacing-base * 4);
// // @spacing-20: calc(@spacing-base * 5);
// // @spacing-24: calc(@spacing-base * 6);
// // @spacing-28: calc(@spacing-base * 7);
// // @spacing-32: calc(@spacing-base * 8);
// // @spacing-page-sm: calc(@spacing-base * 10);
// // @spacing-page-mid: calc(@spacing-base * 20);
// // @spacing-page-mid-120: calc(@spacing-base * 30);
// // @spacing-page-lg: @spacing-page-xl;
// // @spacing-page-xl: calc(@spacing-base * 35);
// // @spacing-page-xxl: calc(@spacing-base * 40);
// // /* @@@@@- Spacing @@@@@@ */
// // @radius-4: 0.28571429rem;
// // @radius-6: 0.42857143rem;
// // @radius-8: 0.57142857rem;
// // @radius-12: 0.85714286rem;
// // @radius-16: 1.14285714rem;
// // @radius-18: 1.28571429rem;
// // @radius-24: 1.71428571rem;
// // @radius-full: 1000px;
// // @input-radius-4: 4px;
// // @menu-radius-6: 6px;
// // /* @@@@@- menu @@@@@@ */
// // @color-bg-property: #262629;
// // @color-bg-property-hover: rgba(255, 255, 255, 0.14);
// // @color-bg-property-active: #008efa;
// // @color-stroke-property: 1px solid #38393d inset;
// // @color-tooltips-property: #14171a;
// // @color-icon-property-hover: #ffffff;
// // @color-icon-property: rgba(255, 255, 255, 0.5);
// // @color-text-property: rgba(255, 255, 255, 0.8);
// // @color-text-property-hover: #ffffff;
// // /* @@@@@- Font @@@@@@ */
// // @Heading-0: bold 4rem/4.64rem @fontFamily;
// // @Heading-1: bold 3.428rem/4rem @fontFamily;
// // @Heading-2: bold 2.369rem/3rem @fontFamily;
// // @Heading-3: bold 1.777rem/2.28rem @fontFamily;
// // @Heading-3-400: 400 1.777rem/2.28rem "PingFang SC", "Microsoft YaHei",
// //   "Microsoft JhengHei", "黑体", arial, "STHeiti", "\5b8b\4f53";
// // @Paragraph-1: bold 1.332rem/2rem @fontFamily;
// // @Paragraph-1-400: 400 1.332rem/2rem "PingFang SC", "Microsoft YaHei",
// //   "Microsoft JhengHei", "黑体", arial, "STHeiti", "\5b8b\4f53";
// // @Paragraph-2: bold 1rem/1.5rem @fontFamily;
// // @Paragraph-2-400: 400 1rem/1.5rem "PingFang SC", "Microsoft YaHei",
// //   "Microsoft JhengHei", "黑体", arial, "STHeiti", "\5b8b\4f53";
// // @Paragraph-3: bold 0.857rem/1.28rem @fontFamily;
// // @Paragraph-3-400: 400 0.857rem/1.28rem "PingFang SC", "Microsoft YaHei",
// //   "Microsoft JhengHei", "黑体", arial, "STHeiti", "\5b8b\4f53";
// // @Paragraph-16: 400 1.142rem/1.64rem "PingFang SC", "Microsoft YaHei",
// //   "Microsoft JhengHei", "黑体", arial, "STHeiti", "\5b8b\4f53";
// // @Paragraph-20: 400 1.428rem/1.92rem "PingFang SC", "Microsoft YaHei",
// //   "Microsoft JhengHei", "黑体", arial, "STHeiti", "\5b8b\4f53";
// // @Paragraph-20-500: 500 1.428rem/1.64rem @fontFamily;
// // @button-1: 500 1rem/1rem @fontFamily;
// // @button-2: 500 1.142rem/1.71rem @fontFamily;
// // @button-3: 500 1.286rem/1.92rem @fontFamily;
// // /* @@@@@- Motion @@@@@@ */
// // /* Duration */
// // @duration-50: 50ms;
// // @duration-150: 150ms;
// // @duration-200: 200ms;
// // @duration-250: 250ms;
// // @duration-300: 300ms;
// // @duration-350: 350ms;
// // @duration-500: 500ms;
// // @duration-600: 600ms;
// // @duration-700: 700ms;
// // @duration-800: 800ms;
// // /* Timing Function */
// // @easeLinear: linear;
// // @easeIn: cubic-bezier(0.32, 0, 0.67, 0);
// // @easeOut: cubic-bezier(0.33, 1, 0.68, 1);
// // @easeOutBack: cubic-bezier(0.34, 1.56, 0.64, 1);
// // /* Delay */
// // @delay-50: 50ms;
// // @delay-100: 100ms;
// // @delay-200: 200ms;
// // @delay-300: 300ms;
// // @delay-400: 400ms;
// // @delay-500: 500ms;
// // /* Transition */
// // @transition-easeLinear-1: all @duration-150 @easeLinear;
// // @transition-easeLinear-2: all @duration-200 @easeLinear;
// // @transition-easeIn-1: all @duration-150 @easeIn;
// // @transition-easeIn-2: all @duration-250 @easeIn;
// // @transition-easeIn-3: all @duration-300 @easeIn;
// // @transition-easeOut-1: all @duration-150 @easeOut;
// // @transition-easeOut-2: all @duration-250 @easeOut;
// // @transition-easeOut-3: all @duration-350 @easeOut;
// // @transition-easeOutBack-1: all @duration-350 @easeOutBack;
// // @transition-easeOutBack-2: all @duration-800 @easeOutBack;
// // @speed-highlightFadeIn: 0s;
// // @speed-highlightFadeOut: 0.15s;
// // @speed-quickTransition: 0.1s;
// // @speed-regularTransition: 0.25;
// // /* @@@@@- Shadow @@@@@@ */
// // @shadow-1: 0px 2px 4px rgba(23, 28, 39, 0.14);
// // @shadow-2: rgba(0, 0, 0, 0.08) 0px 4px 6px 0px,
// //   rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
// // @shadow-3: rgba(23, 25, 29, 0.2) 0px 16px 16px -16px,
// //   rgb(23 25 29 / 3%) 0px 14px 20px 0px, rgb(23 25 29 / 4%) 0px 0px 0px 1px;
// // @shadow-4: rgba(23, 25, 29, 0.5) 0px 16px 16px -16px,
// //   rgb(23 25 29 / 3%) 0px 14px 20px 0px, rgb(23 25 29 / 4%) 0px 0px 0px 1px;
// // @shadow-5: rgba(23, 25, 29, 0.34) 0px 12px 40px -8px,
// //   rgb(23 25 29 / 3%) 0px 8px 40px 0px;
// // @shadow-6: 0px 3px 6px rgba(23, 25, 29, 0.2);
// // @light-panel-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
// //   0px 4px 10px rgba(0, 0, 0, 0.1);
// // @dark-panel-shadow: 0px 0px 0px 1px #45464d, 0px 4px 14px rgba(0, 0, 0, 0.3);
// // @light-popup-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
// //   0px 8px 14px rgba(0, 0, 0, 0.12);
// // @elevation-100-Property: 0px 4px 8px rgba(0, 0, 0, 0.25),
// //   0px 8px 20px rgba(0, 0, 0, 0.16);
// // /* @@@@@- 白板的shadow @@@@@@ */
// // @shadow-xs: 0px 3px 6px -4px rgba(24, 39, 75, 0.02),
// //   0px 6px 8px -4px rgba(24, 39, 75, 0.06);
// // @shadow-sm: 0px 6px 8px -6px rgba(24, 39, 75, 0.04),
// //   0px 8px 16px -6px rgba(24, 39, 75, 0.08);
// // @shadow-md: 0px 6px 12px -6px rgba(24, 39, 75, 0.04),
// //   0px 8px 24px -4px rgba(24, 39, 75, 0.08);
// // @shadow-lg: 0px 6px 14px -6px rgba(24, 39, 75, 0.08),
// //   0px 10px 32px -4px rgba(24, 39, 75, 0.1);
// // /* @@@@@- 白板的border @@@@@@ */
// // @wb-border-default: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
// /* @@@@@- Layout @@@@@@ */
// // :global(.AllCenter) {
// //   display: flex;
// //   justify-content: center;
// //   align-items: center;
// // }
// // :global(.ColumnCenter) {
// //   display: flex;
// //   flex-direction: column;
// //   justify-content: center;
// //   align-items: center;
// // }
// // :global(.Column) {
// //   display: flex;
// //   flex-direction: column;
// // }
// :global(.global-nav-scrolled) {
//   height: 50px;
// }
// :global(.spacing-page-lg) {
//   padding: @spacing-page-lg 0;
// }
// :global(.view-1) {
//   width: 100%;
//   max-width: @view-1;
// }
// :global(.view-2) {
//   width: 100%;
//   max-width: @view-2;
// }
// :global(.width-full) {
//   width: 100%;
// }
// :global(.height-full) {
//   height: 100%;
// }
// @media screen and (min-width: 640px) {
//   :global(.build-in-scale-fade.build-in-animate) {
//     opacity: 1;
//     transform: scale(1) translateY(0);
//   }
//   :global(.build-in-scale-fade) {
//     opacity: 0;
//     transition: opacity 0.7s cubic-bezier(0.16, 1, 0.3, 1),
//       transform 0.7s cubic-bezier(0.16, 1, 0.3, 1);
//     transform: scale(0.95) translateY(40px);
//   }
// }
// :global(.swiper) {
//   overflow: unset;
// }
// :global(.swiper-pagination) {
//   top: 92%;
// }
// :global(.swiper) {
//   height: 120%;
// }
// :global(.swiper-pagination-bullet) {
//   width: 10px !important;
//   height: 10px !important;
// }
// :global(.swiper-container) {
//   height: 120%;
// }
// :global(.jsgif) {
//   position: relative;
//   display: block;
//   height: 100%;
//   width: 100%;
// }
// :global(.jsgif) > canvas {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

// :global(.Heading-0) {
//   font: @Heading-0;
// }
// :global(.Heading-1) {
//   font: @Heading-1;
// }
// :global(.margin-bottom-spacing-page) {
//   margin-bottom: @spacing-page-sm;
// }

// @media screen and (min-width: 640px) {
//   .commWrap {
//     padding-left: 3.4285rem !important;
//     padding-right: 3.4285rem !important;
//   }
// }

// @media screen and (max-width: 1180px) {
//   .navContainer {
//     width: 1180px;
//   }
// }

// @media screen and (max-width: 1100px) {
//   body,
//   html {
//     font-size: 12px;
//   }
// }
// @media screen and (max-height: 900px) {
//   body,
//   html {
//     font-size: 13px;
//   }
// }
// @media screen and (max-width: 640px) {
//   body,
//   html {
//     font-size: 11px;
//   }
//   h1 {
//     font-size: 3rem;
//   }
//   :global(.view-1) {
//     padding: 0 18px !important;
//   }
//   ::-webkit-scrollbar {
//     display: none;
//   }
//   * {
//     -webkit-overflow-scrolling: touch;
//   }
// }
// @media screen and (min-width: 640px) {
//   :global(.view-1) {
//     padding-left: 3.4285rem !important;
//     padding-right: 3.4285rem !important;
//   }
// }

.screen {
//   max-width: @maxWidth;
  width: 90%;
}

