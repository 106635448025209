@import "../../global.module.less";

.button {
  padding: 10px calc(var(--spacing-base) * 5);
  border-radius: 8px;
  font: 500 1.142rem/1.71rem var(--font-family);
  cursor: default;
  box-shadow: var(--shadow-2);
  // margin-right: calc(var(--spacing-base) * 2);
  transition: var(--transition);
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.11) 0px 6px 8px 0px,
      rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }
}

.primary {
  color: white;
  background-color: #009dff;
}

.default {
  color: #243042;
  background-color: white;
}
