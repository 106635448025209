.showHoverContent {
  opacity: 1 !important;
  visibility: visible !important;
  transform: translateY(15px) !important;
}

.hoverContent {
  transition: all 0.2s;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 40px;
  left: 0;
  font-size: 15px;
  width: 100%;
  background-color: white;
  border-radius: 1.14285714rem;
  box-shadow: rgba(23, 25, 29, 0.2) 0px 16px 16px -16px,
    rgb(23 25 29/3%) 0px 14px 20px 0px, rgb(23 25 29/4%) 0px 0px 0px 1px;
  padding: 16px 0;
  a,
  div {
    color: black;
    font-weight: 400;
  }
  .hoverContentItem {
    cursor: default;
    height: 37px;
    line-height: 37px;
    border-radius: 8px;
    width: 80%;
    text-indent: 1em;
    margin: auto;
    transition: all 0.2s;
    &:hover {
      background-color: #f3f7fb;
    }
  }
}
.hoverContentByClick {
  .hoverContent();
  .showHoverContent();
}

.DropDown {
  position: relative;
  &:hover {
    .hoverContent {
      .showHoverContent();
    }
  }
}

.DropDownByClick {
  position: relative;
}
