@import url('../../../../../../global.module.less');

.update-card {
  transition: var(--transition-easeOut-2);
  width: 100%;
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.gifCoverImg {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.updated_card_cover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 240px;
  overflow: hidden;
  border-radius: var(--radius-24);
  background: var(--black-50);
  box-shadow: var(--shadow-2);
  transition: var(--transition-easeOut-2);
  background-size: cover !important;
  background-position: center;
  .cover-img {
    transition: var(--transition-easeOutBack-1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    box-shadow: var(--shadow-3);
    .cover-img {
      transform: scale3d(1.08, 1.08, 1.08);
    }
  }
}

.updated_card_content {
  padding: var(--spacing-24);

  h6 {
    font: var(--Heading-3);
    margin-top: var(--spacing-base);
  }

  p {
    font: var(--Paragraph-16);
    margin: var(--spacing-16) 0;
    opacity: 0.7;
  }

  span {
    font: var(--Paragraph-2-400);
    color: var(--button-blue-1);
    font-weight: 500;
  }
}

@media screen and (max-width: 640px) {
  .updated_card_cover {
    height: fit-content !important;
  }
}
