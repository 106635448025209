.backTop {
  position: fixed;
  width: 36px;
  height: 36px;
  background-color: white;
  border-radius: 50%;
  right: 20px;
  bottom: 65px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  i {
    width: 16px;
    height: 16px;
  }
}
