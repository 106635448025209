@import "../../../../../global.module.less";

.compare {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  padding: 40px 32px;
  background-color: var(--black-50);
  border-radius: var(--radius-24);
}

.primary-compare-card {
  z-index: 1020;
  background-color: var(--button-blue-1) !important;
  box-shadow: var(--shadow-4) !important;

  @media screen and (max-width: 640px) {
    width: 68% !important;
  }

  @media screen and (max-height: 900px) {
    height: fit-content;
  }

  i {
    filter: invert(1) contrast(2);
  }

  span {
    color: var(--white);
  }

  li {
    border-bottom: 1px solid rgb(255 255 255 / 20%) !important;
    color: var(--white);
  }
}

.compare-hover-bg {
  z-index: 1;
  border-color: transparent !important;

  &:after {
    opacity: 1 !important;
    background: rgba(255, 255, 255, 0.3) !important;
    transform: scale(1) !important;
  }
}
.compare-hover-bg-other {
  .compare-hover-bg();
  &::after {
    opacity: 1 !important;
    background-color: white !important;
    transform: scale(1) !important;
  }
}

.compare-card_top {
  display: flex;
  align-items: center;
  margin-bottom: 48px;

  .compare-card_top_logo {
    width: 32px;
    height: 32px;
  }

  span {
    margin-left: 8px;
    font: var(--Heading-3);
  }
}

.compare-card_btm {
  @media screen and (max-height: 900px) {
    li {
      padding: var(--spacing-8) 0 !important;
      font-size: 1.1rem !important;
    }
  }
  width: 100%;

  li {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-16) 0;
    border-bottom: 1px solid rgb(23 28 39 / 15%);
    font: var(--Paragraph-1-400);
    transition: var(--transition-easeOut-3);

    p,
    i {
      z-index: 1;
    }

    i {
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:after {
      opacity: 0;
      transform: scale(0.9);
      content: "";
      display: block;
      position: absolute;
      left: -12px;
      top: -1px;
      background: var(--white);
      width: calc(100% + 2px);
      height: 100%;
      border-radius: var(--radius-8);
      padding: 0 var(--spacing-12);
      transition: var(--transition-easeOut-3);
    }
  }
}

.no-support {
  opacity: 0.4;
  z-index: 1;
}
