@import url("../../../../../global.module.less");


.resource-btn-wrap {
  width: 140px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 999px;
  font-size: 1.286rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.25s cubic-bezier(0.33, 1, 0.68, 1);
  font-family: var(--font-family);
  font-weight: 500;

  &:hover {
    border-color: rgba(255, 255, 255, 0.5);

    .arrow-right {
      transform: translateX(3px);
    }
  }

  &:active {
    transform: scale(0.94);
    border-color: #4BB0FC;
    background-color: #4BB0FC;
  }

  .arrow-right {
    width: 20px;
    height: 20px;
    margin-left: var(--spacing-base);
    transition: 0.25s cubic-bezier(0.33, 1, 0.68, 1);
  }

  img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 640px) {
  .resource-btn-wrap {
    width: 120px;
    height: 36px;
  }
}
