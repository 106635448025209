@import "../../../../../global.module.less";

.right-card {
  height: 100%;
  border-radius: 20px;
  box-shadow: 0px 12px 32px rgba(5, 8, 13, 1);
  position: relative;
  padding: 40px 40px 0;
  background: linear-gradient(
    160deg,
    rgba(140, 169, 255, 0.14) 14%,
    rgba(111, 129, 176, 0.14) 93%
  );
  border: 1px solid rgba(255, 255, 255, 0.06);
  box-shadow: 0px 12px 32px rgb(5 8 13);
}

.right-card-wrap {
  .title {
    background: rgba(255, 255, 255, 0.11);
    border-radius: 7px;
    color: rgba(255, 255, 255, 1);
    font-size: 1.14rem;
    width: 88px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    margin-bottom: var(--spacing-16);
    font-family: var(--font-family);
    font-weight: 500;
  }

  .subtitle {
    margin-bottom: var(--spacing-32);
    font-size: 2.85rem;
    line-height: 2.85rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
  }

  .content {
    margin-bottom: var(--spacing-24);

    .design-activity {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;

      .design-left,
      .design-right {
        flex: 1;
      }

      .design-left {
        margin-right: 25px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: flex-end;

        .img-wrap {
          cursor: pointer;

          .img {
            position: absolute;
            width: 100%;
            border-radius: 12px;
            border: 1px solid rgba(255, 255, 255, 0.04);
            max-height: 164px;
            top: 0;
            cursor: pointer;
          }
        }
      }

      .design-right {
        overflow: hidden;
        cursor: pointer;
        margin-top: 42px;

        .small-title {
          color: rgba(0, 157, 255, 1);
          font-size: 0.857rem;
          line-height: 0.857rem;
          font-weight: 500;
          margin-bottom: var(--spacing-base);
          font-family: var(--font-family);
          font-weight: 500;
        }

        .big-title {
          color: rgba(255, 255, 255, 1);
          font-size: 1.429rem;
          line-height: 1.429rem;
          font-weight: 500;
          margin-bottom: var(--spacing-16);
          font-family: var(--font-family);
          font-weight: 500;

          img {
            vertical-align: baseline;
            opacity: 0;
            transform: scale(0.8);
            transition: 0.25s cubic-bezier(0.33, 1, 0.68, 1);
            transform-origin: left bottom;
            vertical-align: top;
          }
        }

        .desc {
          color: rgba(255, 255, 255, 1);
          font-size: 0.929rem;
          margin-bottom: var(--spacing-32);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-family: var(--font-family);
          font-weight: 500;
        }

        .numbers {
          display: flex;

          .traffic,
          .join {
            display: flex;

            :first-child {
              margin-right: 2px;
            }
          }

          .traffic {
            margin-right: var(--spacing-12);
          }

          .number {
            font-family: var(--font-family);
            font-weight: 500;
            display: contents;
            font-size: 0.929rem;
            color: rgba(255, 255, 255, 0.9);
          }
        }
      }

      &:hover {
        .design-right {
          .big-title {
            img {
              opacity: 1 !important;
              transform: scale(1) !important;
            }
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: -65px;
    width: 100%;

    .change {
      display: flex;

      .left-icon-wrap,
      .right-icon-wrap {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.25s cubic-bezier(0.33, 1, 0.68, 1);
        background: rgba(255, 255, 255, 0.14);
        opacity: 0.8;
        border-radius: 50%;

        .left-icon,
        .right-icon {
          width: 8px;
          height: 8px;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.28);
        }

        &:active {
          background: rgba(255, 255, 255, 0.28);
          transform: scale(0.94);
        }
      }

      .left-icon-wrap {
        margin-right: 16px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .right-card {
    background: linear-gradient(
      160deg,
      rgba(140, 169, 255, 0.14) 0%,
      rgba(111, 129, 176, 0.14) 100%
    );
    padding: 32px 32px 0;
  }

  .right-card-wrap {
    padding-bottom: 40px !important;

    .title {
      width: 78px !important;
      height: 30px !important;
      margin-bottom: 10px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px !important;
    }

    .subtitle {
      font-size: 26px !important;
      margin-bottom: 40px !important;
    }

    .content {
      .design-activity {
        flex-direction: column;
        height: 310px;

        .design-left {
          width: 271px;
          order: 1;
          margin-right: 0;
        }

        .design-right {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          margin-top: 0 !important;
          width: 100%;
          
          .small-title {
            font-size: 12px;
            line-height: 12px;
            margin-bottom: 6px;
          }
          .big-title {
            margin-bottom: 12px;
            font-size: 20px;
          }
          .desc {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 13px;
          }

          .numbers {
            align-items: center;

            .traffic,
            .join {
              align-items: center;
            }
          }
        }
      }
    }

    .footer {
      bottom: -46px;

      .change {
        justify-content: center;
        align-items: baseline;
      }
    }
  }
}
