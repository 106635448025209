@import url("../../../../global.module.less");

.logined {
  display: flex;
  flex-direction: column;
  align-items: center;
  .describe {
    margin-top: 40px;
  }
  .btnList {
    display: flex;
    column-gap: 20px;
  }
}

.register {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100% !important;
  input {
    height: 100% !important;
    border: none;
    outline: none;
    font: 400 1.332rem/2rem "PingFang SC", "Microsoft YaHei",
      "Microsoft JhengHei", "黑体", arial, "STHeiti";
    margin-left: 60px;
  }
  i {
    opacity: 0.8;
    position: absolute;
    display: inline-block;
    margin-left: 20px;
    width: 25px;
    height: 100%;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.social {
  display: flex;
  justify-content: center;
  column-gap: 12px;
  // margin-top: 18px;
  // margin-bottom: 8px;
  margin-block: 18px 8px;
  align-items: center;
  font-size: 12px;
  font-family: var(--font-family);
  cursor: default;
  div {
    display: flex;
    align-items: center;
    img {
      margin-right: 4px;
    }
  }
}

.maxdescribe {
  max-width: 300px;
}
.describe {
  text-align: center;
  font-size: 12px;
  opacity: 0.7;
  line-height: 1.5;
}

.input-wrapper {
  position: relative;
  // width: 500px;
  height: 64px;
  padding: 6px;
  border-radius: var(--radius-16);
  background-color: #fff;
  display: flex;
  align-items: center;
  transition: var(--transition-easeOut-2);

  &:hover {
    box-shadow: var(--shadow-3);
  }

  i {
    width: 25px;
    height: 25px;
    position: absolute;
    left: 20px;
    display: flex;
    align-items: center;
    opacity: 0.4;
    transition: var(--transition-easeOut-2);

    svg {
      width: 100%;
    }
  }

  .hero-cta {
    // width: 50%;
    height: fit-content;

    div[data-type="button"] {
      border-radius: var(--radius-12);
      font-size: 20px;

      @media screen and (max-height: 900px) {
        font-size: 18px;
      }
    }
  }

  .input_avatars {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;

    img {
      position: relative;
      width: 60px;
      height: 60px;
      border-radius: 1000px;
      border: 4px solid #fff;
      box-shadow: 0px 2px 3px rgba(23, 28, 39, 0.1);

      &:nth-of-type(1) {
        margin-left: 35px;
      }
      &:nth-of-type(2) {
        left: -25px;
      }
      &:nth-of-type(3) {
        left: -50px;
      }
    }
  }

  div {
    width: 200px;
    font-size: 18px;
  }

  input {
    width: 80%;
    height: 100%;
    border-radius: var(--radius-18);
    outline: none;
    border: none;
    padding: 0 0 0 46px;
    font: var(--Paragraph-1-400);
    transition: var(--transition-easeOut-2);

    &::placeholder {
      opacity: 0.7;
    }
  }
}

@media screen and (max-height: 900px) {
  .input-wrapper {
    // width: 448px;
    height: 54px;
  }
}
