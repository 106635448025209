@import url("../../../../global.module.less");

.resource {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: var(--white);
  color: var(--white);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 82%;
}

.resource-wrap {
  width: 100%;
  overflow: hidden;
  background: rgba(15, 22, 31, 1);

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-page-mid-120) 0;

  .resource-wrap-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: calc(var(--spacing-base) * 16);

    .title {
      border: 1px solid rgba(0, 157, 255, 0.4);
      background: linear-gradient(
        135deg,
        rgba(0, 123, 255, 0.12) 0%,
        rgba(0, 179, 255, 0.12) 100%
      );
      margin-bottom: 16px;
      border-radius: 100px;
      padding: 0 0.929rem;
      height: 35px;
      display: flex;
      align-items: center;

      div {
        font-size: 1.429rem;
        color: rgba(0, 157, 255, 1);
        font-family: var(--font-family);
        font-weight: 500;
      }
    }

    strong {
      animation: flow 5s ease-in-out infinite;
      background: linear-gradient(
        -45deg,
        #fff566,
        #a8ffca,
        #ffad61,
        #ff857a,
        #fff,
        #ff5cc9,
        #6279ea,
        #66ffe6
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 300%;
      color: var(--white);

      var(--keyframes) flow {
        0%,
        100% {
          background-position: 0 50%;
        }
        50% {
          background-position: 100% 50%;
        }
      }
    }
  }

  .resource-wrap-bottom {
    position: relative;
    max-width: 1344px;

    .bg {
      position: absolute;
      width: calc(100% + 21.143rem);
      height: calc(100% + 8.786rem);
      top: -8.786rem;
      left: -10.572rem;
    }

    .resource-wrap_btm {
      position: relative;
      height: 452px;
      display: flex;
      align-items: center;
      background-color: rgba(28, 35, 46, 1);
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0px 12px 32px rgb(5 8 13);

      .resource-card {
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        background: rgba(20, 28, 38, 0.85);
        box-shadow: 19px 0px 28px -16px rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(24px);
        padding: calc(var(--spacing-base) * 10) 0 0 calc(var(--spacing-base) * 10);
        z-index: 99;
        width: calc(100% * 0.316);
        border-radius: 20px;

        .img-fill {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }

        .title {
          background: rgba(255, 255, 255, 0.08);
          border-radius: 7px;
          color: rgba(255, 255, 255, 1);
          font-size: 1.14rem;
          width: 88px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          margin-bottom: var(--spacing-16);
          font-family: var(--font-family);
          font-weight: 500;
        }

        .title1 {
          margin-bottom: calc(var(--spacing-base) * 16);

          .text {
            font-size: 2.85rem;
            line-height: 2.85rem;
            color: #fff;
            font-family: var(--font-family);
            font-weight: 500;
          }

          .text1 {
            margin-bottom: var(--spacing-8);
          }

          .text2 {
            opacity: 0.5;
          }
        }

        .statistic-wrap {
          display: flex;
          margin-bottom: calc(var(--spacing-base) * 16);
        }

        .button {
          width: 140px;
          height: 40px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 999px;
          cursor: pointer;

          &:hover {
            border-color: rgba(255, 255, 255, 0.5);
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .resource-scroll-wrap {
        z-index: 1;
        display: flex;
        position: absolute;
        left: 45px;
        flex-direction: column;
        top: 0;

        & > div {
          margin: calc(var(--spacing-base) * 16) 0 calc(var(--spacing-base) * 14) 0;
        }

        a {
          width: 100%;
          img {
            object-fit: cover;
          }
        }

        .resource-scroll-2 {
          left: -20%;
        }
      }

      &_covers {
        position: relative;
        z-index: -1;
        display: flex;

        >img {
          transition: all 250ms cubic-bezier(0.05, 0.03, 0.35, 1) 0s;
          object-fit: cover;
          border-radius: var(--radius-16);
          border: 1px solid rgba(255, 255, 255, 0.04);
        }

        .scroll-card {
          width: 354px;
        }

        a {
          z-index: 1;
        }

        & > div {
          position: relative;
          height: 240px;
          margin: 0 15px;
          border-radius: var(--radius-16);
          overflow: hidden;
          transition: var(--transition-easeOutBack-1);
          display: flex;
          justify-content: center;
          box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%),
            0 30px 60px -30px rgb(0 0 0 / 68%);

          span {
            display: block;
            position: absolute;
            width: 107%;
            height: 126%;
            background-color: var(--white);
            border-radius: 24px;
            box-shadow: 0 2px 12px #eee;
            z-index: 0;
            top: -12px;
          }

          &:hover {
            z-index: 1;
            transform: scale3d(1.06, 1.06, 1.06);
            margin: 0 19px;
          }
        }
      }

      .resource-wrap_top_right-wrap {
        display: flex;
        justify-content: center;
        flex: 1;

        .resource-wrap_top_right {
          display: flex;
          align-items: flex-end;
          position: absolute;
          margin-bottom: -4px;
          bottom: var(--spacing-24);
          z-index: 99;
          width: 65%;
        }
      }
    }

    .two-card {
      display: flex;
      width: 100%;
      margin: var(--spacing-24) auto 0;
      position: relative;

      .two-card-bg {
        width: 100vw;
        position: absolute;
        height: 1105px;
      }

      .left,
      .right {
        border-radius: 20px;
        width: 50%;
      }

      .left {
        width: calc(50% - 12px);
        margin-right: 12px;
      }

      .right {
        margin-left: 12px;
      }
    }
  }
}

.resourceBtn {
  &:active {
    border-color: rgba(0, 157, 255, 1);
    background-color: rgba(0, 157, 255, 1);
  }
}

@media screen and (max-width: 1392px) {
  .resource-wrap-bottom {
    width: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .resource-wrap {
    padding-left: 3.4285rem !important;
    padding-right: 3.4285rem !important;
  }
}

@media screen and (max-width: 640px) {
  .resource-wrap_btm_covers {
    .scroll-card {
      margin: 0 7.5px !important;
    }
  }

  .resource-wrap {
    padding: 64px 0 !important;

    h1 {
      font: var(--Heading-0) !important;
      margin-bottom: 0 !important;
    }

    .resource-wrap-top {
      margin-bottom: 40px !important;

      .title {
        height: 25px !important;
        padding: 5px 11px 6px !important;

        div {
          font-size: 14px !important;
          line-height: 14px !important;
        }
      }
    }
  }

  .two-card {
    width: 100vw !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-top: 24px !important;

    .left,
    .right {
      width: calc(100vw - 40px) !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .right {
      margin-top: 24px !important;
      height: 532px !important;
    }
  }

  .resource-wrap_btm {
    height: auto !important;
    width: calc(100vw - 40px) !important;
    justify-content: center !important;
    margin: 0 auto;
    background-color: transparent !important;

    .resource-card {
      width: 100% !important;
      backdrop-filter: none !important;
      position: relative !important;
      padding-bottom: 30px !important;
      background: linear-gradient(
        170.39deg,
        rgba(140, 169, 255, 0.14) 10.9%,
        rgba(111, 129, 176, 0.14) 98.62%
      ) !important;
      box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.06),
        0px 12px 32px rgba(5, 8, 13, 1) !important;
      padding-top: 32px !important;
      padding-left: 32px !important;

      .title {
        margin-bottom: 10px !important;
      }

      .title1 {
        .text {
          font-size: 26px !important;
          line-height: 26px !important;
          font-weight: 500 !important;
        }
      }

      .statistic-wrap {
        margin-bottom: 40px !important;
      }

      .phone-resource-scroll-wrap {
        position: relative;
        height: 148px;
        margin-bottom: 40px;

        .resource-scroll-wrap {
          left: -170px !important;

          & > div {
            margin: 0 !important;
          }

          .scroll-card {
            width: 218px !important;
            height: 148px !important;
          }
        }
      }

      .title {
        width: 78px !important;
        height: 30px !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .right {
    margin-top: var(--spacing-24);
    position: relative;
  }
}
