@import url("../../global.module.less");

.primary-button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: var(--white);
  padding: 10px var(--spacing-20);
  background: var(--button-blue-1);
  border-radius: var(--radius-8);
  transition: var(--transition-easeOut-3);
  box-shadow: var(--shadow-2);
  word-break: keep-all;
  font: var(--button-2);

  &:hover {
    background: var(--button-blue-2);
    box-shadow: var(--shadow-3);
  }

  &:active {
    background: var(--button-blue-1);
    transform: scale3d(0.94, 0.94, 0.94);
  }
}

.button-disabled {
  opacity: 0.5;
}
