@import url('../../../../../global.module.less');

.statistic-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 var(--spacing-24);
  align-items: flex-start;

  &:first-child {
    padding-left: 0;
  }

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.14);
  }

  span {
    font: var(--Heading-3);
    color: var(--white);
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    font-family: var(--font-family);
    font-weight: 500;
  }

  p {
    font: var(--Paragraph-16);
    margin-top: var(--spacing-base);
    color: var(--white);
    opacity: 0.6;
    font-size: 14px;
    line-height: 14px;
    font-family: var(--font-family);
    font-weight: 500;
  }
}

@media screen and (max-width: 640px) {
  .statistic-item {
    padding: 0 20px;
    span {
      margin-bottom: 8px;
      font-weight: 500;
    }

    p {
      margin-top: 0;
      font-weight: 400;
    }
  }
}
