@import "../../../../global.module.less";

.updated-log {
  display: flex;
  justify-content: center;
  padding: var(--spacing-page-lg) 0;
  background-color: var(--white);
}

.updated-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;

  &_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-page-mid);

    .title {
      display: flex;
      align-items: center;
      flex-direction: column;

      header {
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: var(--spacing-page-sm);

        p {
          max-width: 560px;
        }
      }
    }
  }

  &_btm {
    display: grid;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;

    // .updated_card_cover .cover-img {
    //   /* width: 86%; */
    // }

    .updated_card {
      transition: var(--transition-easeOut-2);

      cursor: pointer;
      &:not(&:last-child) {
        margin-right: 24px;
      }

      //   &:hover {
      //     /* border: 1px solid var(--black-400); */
      //   }
    }
  }
}

@media screen and (max-width: 640px) {
  width: 100vw;
  padding: calc(var(--spacing-page-lg) - 24px) 0 var(--spacing-page-mid) 0 !important;

  .updated-wrap_top {
    margin-bottom: 24px !important;
  }

  .updated-wrap_btm {
    padding: 0 8px !important;
  }
}

@media screen and (max-width: 368px) {
  .updated-wrap_btm {
    grid-template-columns: none;
  }
}
