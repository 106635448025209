button {
  border: none;
  outline: none;
  background-color: initial;
}

body {
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
  margin: 0;
  padding: 0;
}

.root {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

a {
  outline: none;
  user-select: none;
}

video {
  max-width: 100%;
}

.app {
  --color-shadow-light: rgba(0, 0, 0, 0.15);
  --color-shadow-dark: rgba(0, 0, 0, 0.6);
  /* ----------- View ------------ */
  --view-1: 1440px;
  --view-2: 1100px;

  /* ----------- Color ------------ */
  --white: rgb(255, 255, 255);
  --white-1: rgba(255, 255, 255, 0.5);
  --white-2: rgba(255, 255, 255, 0.1);
  --oceanblue-900: rgba(0, 8, 67, 1);
  --oceanblue-800: rgba(0, 13, 68, 1);
  --oceanblue-700: rgba(2, 27, 82, 1);
  --oceanblue-600: rgba(1, 39, 96, 1);
  --oceanblue-500: rgba(0, 46, 104, 1);
  --oceanblue-400: rgba(0, 70, 122, 1);
  --oceanblue-300: rgba(1, 94, 138, 1);
  --oceanblue-200: rgba(2, 137, 172, 1);
  --oceanblue-100: rgba(124, 185, 207, 1);
  --oceanblue-50: hsl(202, 100%, 95%);
  --black-900: hsl(216deg 30% 20%);
  --black-800: hsl(220deg 12% 16%);
  --black-700: rgba(102, 102, 103, 1);
  --black-600: rgba(122, 122, 123, 1);
  --black-500: rgba(163, 163, 165, 1);
  --black-400: rgba(194, 194, 195, 1);
  --black-300: rgba(228, 228, 230, 1);
  --black-200: rgba(241, 241, 242, 1);
  --black-100: hsl(216deg 14% 92%);
  --black-50: hsl(210, 51%, 97%);
  --coffee-900: rgba(93, 56, 50, 1);
  --coffee-800: rgba(110, 70, 62, 1);
  --coffee-700: rgba(126, 83, 72, 1);
  --coffee-600: rgba(143, 96, 82, 1);
  --coffee-500: rgba(156, 106, 89, 1);
  --coffee-400: rgba(174, 129, 116, 1);
  --coffee-300: rgba(192, 152, 140, 1);
  --coffee-200: rgba(214, 181, 174, 1);
  --coffee-100: rgba(236, 210, 202, 1);
  --coffee-50: rgba(254, 236, 227, 1);
  --spring-green-900: rgba(66, 112, 93, 1);
  --spring-green-800: rgba(80, 143, 120, 1);
  --spring-green-700: rgba(87, 160, 135, 1);
  --spring-green-600: rgba(95, 176, 152, 1);
  --spring-green-500: rgba(102, 191, 165, 1);
  --spring-green-400: rgba(112, 202, 178, 1);
  --spring-green-300: rgba(133, 214, 192, 1);
  --spring-green-200: rgba(168, 227, 210, 1);
  --spring-green-100: rgba(201, 236, 227, 1);
  --spring-green-50: hsl(170deg 48% 86%);
  --indigo-900: rgba(19, 56, 145, 1);
  --indigo-800: rgba(35, 74, 166, 1);
  --indigo-700: rgba(44, 84, 178, 1);
  --indigo-600: rgba(55, 95, 190, 1);
  --indigo-500: rgba(61, 103, 202, 1);
  --indigo-400: rgba(97, 127, 208, 1);
  --indigo-300: rgba(127, 152, 215, 1);
  --indigo-200: rgba(165, 183, 226, 1);
  --indigo-100: rgba(201, 210, 236, 1);
  --indigo-50: rgba(236, 237, 247, 1);
  --purple-900: rgba(44, 43, 159, 1);
  --purple-800: rgba(66, 56, 173, 1);
  --purple-700: rgba(79, 61, 183, 1);
  --purple-600: rgba(94, 69, 191, 1);
  --purple-500: rgba(102, 74, 198, 1);
  --purple-400: rgba(126, 100, 206, 1);
  --purple-300: rgba(149, 128, 216, 1);
  --purple-200: rgba(179, 167, 225, 1);
  --purple-100: rgba(211, 202, 236, 1);
  --purple-50: rgba(238, 233, 247, 1);
  --orchid-900: rgba(127, 36, 142, 1);
  --orchid-800: rgba(155, 47, 155, 1);
  --orchid-700: rgba(171, 53, 163, 1);
  --orchid-600: rgba(189, 62, 170, 1);
  --orchid-500: rgba(203, 66, 177, 1);
  --orchid-400: rgba(213, 93, 186, 1);
  --orchid-300: rgba(220, 124, 198, 1);
  --orchid-200: rgba(227, 164, 213, 1);
  --orchid-100: rgba(237, 202, 228, 1);
  --orchid-50: rgba(247, 235, 244, 1);
  --button-blue-1: hsl(203deg 100% 50%);
  --button-blue-2: hsl(206deg 100% 49%);
  --button-blue-3: hsl(203deg 100% 75%);
  --popup-mask-bg: rgba(0, 0, 0, 0.13);
  /* ---------- workspace -------------*/
  --workspace-dark-bgColor-1: #303136;
  --workspace-light-bgColor-1: #fff;
  --workspace-dark-border-1: rgba(255, 255, 255, 0.1);
  --workspace-light-border-1: rgba(232, 232, 232, 1);
  --workspace-light-color-1: rgba(32, 32, 32, 1);


  /* ----------- Spacing ------------ */
  --button-primary: rgba(0, 157, 255, 1);
  --button-primary-hover: rgba(0, 142, 250, 1);
  --button-primary-disabled: rgba(0, 157, 255, 1, 0.5);
  /* ----------- Spacing ------------ */
  --input-normal: 1px solid rgba(220, 222, 230, 1);
  --input-hover-border: 1px solid rgba(171, 172, 179, 1);
  --input-focus-border: 2px solid rgba(0, 142, 250, 1);
  --input-focus-bgColor: rgba(245, 250, 255, 1);
  --input-placeholder-fontColor: rgba(34, 34, 34, 0.4);

  /* ----------- Spacing ------------ */
  --spacing-2: calc(var(--spacing-base) / 2);
  --spacing-base: 0.2857142857rem;
  --spacing-8: calc(var(--spacing-base) * 2);
  --spacing-12: calc(var(--spacing-base) * 3);
  --spacing-16: calc(var(--spacing-base) * 4);
  --spacing-20: calc(var(--spacing-base) * 5);
  --spacing-24: calc(var(--spacing-base) * 6);
  --spacing-28: calc(var(--spacing-base) * 7);
  --spacing-32: calc(var(--spacing-base) * 8);
  --spacing-page-sm: calc(var(--spacing-base) * 10);
  --spacing-page-mid: calc(var(--spacing-base) * 20);
  --spacing-page-mid-120: calc(var(--spacing-base) * 30);
  --spacing-page-lg: var(--spacing-page-xl);
  --spacing-page-xl: calc(var(--spacing-base) * 35);
  --spacing-page-xxl: calc(var(--spacing-base) * 40);

  /* ----------- Spacing ------------ */
  --radius-4: 0.2857142857rem;
  --radius-6: 0.4285714286rem;
  --radius-8: 0.5714285714rem;
  --radius-12: 0.8571428571rem;
  --radius-16: 1.1428571429rem;
  --radius-18: 1.2857142857rem;
  --radius-24: 1.7142857143rem;
  --radius-full: 1000px;

  --input-radius-4: 4px;
  --menu-radius-6: 6px;

  /* ----------- menu ------------ */
  --color-bg-property: rgba(38, 38, 41, 1);
  --color-bg-property-hover: rgba(255, 255, 255, 0.14);
  --color-bg-property-active: rgba(0, 142, 250, 1);
  --color-stroke-property: 1px solid rgba(56, 57, 61, 1) inset;
  --color-tooltips-property: rgba(20, 23, 26, 1);
  --color-icon-property-hover: rgba(255, 255, 255, 1);
  --color-icon-property: rgba(255, 255, 255, 0.5);
  --color-text-property: rgba(255, 255, 255, 0.8);
  --color-text-property-hover: rgba(255, 255, 255, 1);
  /* ----------- Font ------------ */
  --font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Helvetica Neue, sans-serif;
  --Heading-0: bold 4rem/4.64rem var(--font-family);
  --Heading-1: bold 3.428rem/4rem var(--font-family);
  --Heading-2: bold 2.369rem/3rem var(--font-family);
  --Heading-3: bold 1.777rem/2.28rem var(--font-family);
  --Heading-3-400: 400 1.777rem/2.28rem 'PingFang SC', 'Microsoft YaHei', 'Microsoft JhengHei',
    '黑体', arial, 'STHeiti', '\5b8b\4f53';
  --Paragraph-1: bold 1.332rem/2rem var(--font-family);
  --Paragraph-1-400: 400 1.332rem/2rem 'PingFang SC', 'Microsoft YaHei', 'Microsoft JhengHei',
    '黑体', arial, 'STHeiti', '\5b8b\4f53';
  --Paragraph-2: bold 1rem/1.5rem var(--font-family);
  --Paragraph-2-400: 400 1rem/1.5rem 'PingFang SC', 'Microsoft YaHei', 'Microsoft JhengHei', '黑体',
    arial, 'STHeiti', '\5b8b\4f53';
  --Paragraph-3: bold 0.857rem/1.28rem var(--font-family);
  --Paragraph-3-400: 400 0.857rem/1.28rem 'PingFang SC', 'Microsoft YaHei', 'Microsoft JhengHei',
    '黑体', arial, 'STHeiti', '\5b8b\4f53';
  --Paragraph-16: 400 1.142rem/1.64rem 'PingFang SC', 'Microsoft YaHei', 'Microsoft JhengHei',
    '黑体', arial, 'STHeiti', '\5b8b\4f53';
  --Paragraph-20: 400 1.428rem/1.92rem 'PingFang SC', 'Microsoft YaHei', 'Microsoft JhengHei',
    '黑体', arial, 'STHeiti', '\5b8b\4f53';
  --Paragraph-20-500: 500 1.428rem/1.64rem var(--font-family);
  --button-1: 500 1rem/1rem var(--font-family);
  --button-2: 500 1.142rem/1.71rem var(--font-family);
  --button-3: 500 1.286rem/1.92rem var(--font-family);

  /* ----------- Motion ------------ */
  /* Duration */
  --duration-50: 50ms;
  --duration-150: 150ms;
  --duration-200: 200ms;
  --duration-250: 250ms;
  --duration-300: 300ms;
  --duration-350: 350ms;
  --duration-500: 500ms;
  --duration-600: 600ms;
  --duration-700: 700ms;
  --duration-800: 800ms;

  /* Timing Function */
  --easeLinear: linear;
  --easeIn: cubic-bezier(0.32, 0, 0.67, 0);
  --easeOut: cubic-bezier(0.33, 1, 0.68, 1);
  --easeOutBack: cubic-bezier(0.34, 1.56, 0.64, 1);

  /* Delay */
  --delay-50: 50ms;
  --delay-100: 100ms;
  --delay-200: 200ms;
  --delay-300: 300ms;
  --delay-400: 400ms;
  --delay-500: 500ms;

  /* Transition */
  --transition-easeLinear-1: all var(--duration-150) var(--easeLinear);
  --transition-easeLinear-2: all var(--duration-200) var(--easeLinear);
  --transition-easeIn-1: all var(--duration-150) var(--easeIn);
  --transition-easeIn-2: all var(--duration-250) var(--easeIn);
  --transition-easeIn-3: all var(--duration-300) var(--easeIn);
  --transition-easeOut-1: all var(--duration-150) var(--easeOut);
  --transition-easeOut-2: all var(--duration-250) var(--easeOut);
  --transition-easeOut-3: all var(--duration-350) var(--easeOut);
  --transition-easeOutBack-1: all var(--duration-350) var(--easeOutBack);
  --transition-easeOutBack-2: all var(--duration-800) var(--easeOutBack);
  --speed-highlightFadeIn: 0s;
  --speed-highlightFadeOut: 0.15s;
  --speed-quickTransition: 0.1s;
  --speed-regularTransition: 0.25;

  /* ----------- Shadow ------------ */
  --shadow-1: 0px 2px 4px rgba(23, 28, 39, 0.14);
  --shadow-2: rgba(0, 0, 0, 0.08) 0px 4px 6px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  --shadow-3: rgb(23 25 29 / 20%) 0px 16px 16px -16px, rgb(23 25 29 / 3%) 0px 14px 20px 0px,
    rgb(23 25 29 / 4%) 0px 0px 0px 1px;
  --shadow-4: rgb(23 25 29 / 50%) 0px 16px 16px -16px, rgb(23 25 29 / 3%) 0px 14px 20px 0px,
    rgb(23 25 29 / 4%) 0px 0px 0px 1px;
  --shadow-5: rgb(23 25 29 / 34%) 0px 12px 40px -8px, rgb(23 25 29 / 3%) 0px 8px 40px 0px;
  --shadow-6: 0px 3px 6px rgb(23 25 29 / 20%);
  --light-panel-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 4px 10px rgba(0, 0, 0, 0.1);
  --dark-panel-shadow: 0px 0px 0px 1px rgba(69, 70, 77, 1),
    0px 4px 14px rgba(0, 0, 0, 0.3);
  --light-popup-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 8px 14px rgba(0, 0, 0, 0.12);
  --elevation-100-Property: 0px 4px 8px rgba(0, 0, 0, 0.25),
    0px 8px 20px rgba(0, 0, 0, 0.16);
}

.global-nav-scrolled {
  height: 50px;
}

.spacing-page-lg {
  padding: var(--spacing-page-lg) 0;
}

.view-1 {
  width: 100%;
  max-width: var(--view-1);
}

.view-2 {
  width: 100%;
  max-width: var(--view-2);
}

.width-full {
  width: 100%;
}

.height-full {
  height: 100%;
}

.margin-bottom-spacing-page {
  margin-bottom: var(--spacing-page-sm);
}

@media screen and (min-width: 640px) {
  .build-in-scale-fade.build-in-animate {
    opacity: 1;
    transform: scale(1) translateY(0);
  }

  .build-in-scale-fade {
    opacity: 0;
    transition: opacity 0.7s cubic-bezier(0.16, 1, 0.3, 1),
      transform 0.7s cubic-bezier(0.16, 1, 0.3, 1);
    transform: scale(0.95) translateY(40px);
  }

  .view-1 {
    padding-left: 3.4285rem !important;
    padding-right: 3.4285rem !important;
  }

  .commWrap {
    padding-left: 3.4285rem !important;
    padding-right: 3.4285rem !important;
  }

}


/* .Heading-0 {
  font: @Heading-0;
}
.Heading-1 {
  font: @Heading-1;
} */

@media screen and (max-width: 1180px) {
  .navContainer {
    width: 1180px;
  }
}

@media screen and (max-width: 1100px) {

  body,
  html {
    font-size: 12px;
  }
}

@media screen and (max-height: 900px) {

  body,
  html {
    font-size: 13px;
  }
}


@media screen and (max-width: 640px) {

  body,
  html {
    font-size: 11px;
  }

  .app {
    --Heading-0: bold 3.2rem/3.8rem 'Noto Sans SC', '-apple-system', 'sans-serif' !important;
    --spacing-2: calc(var(--spacing-base) / 2) !important;
    --spacing-base: 0.2rem !important;
    --spacing-8: calc(var(--spacing-base) * 2) !important;
    --spacing-12: calc(var(--spacing-base) * 3) !important;
    --spacing-16: calc(var(--spacing-base) * 4) !important;
    --spacing-20: calc(var(--spacing-base) * 5) !important;
    --spacing-24: calc(var(--spacing-base) * 6) !important;
    --spacing-28: calc(var(--spacing-base) * 7) !important;
    --spacing-32: calc(var(--spacing-base) * 8) !important;
    --spacing-page-sm: calc(var(--spacing-base) * 10) !important;
    --spacing-page-mid: calc(var(--spacing-base) * 20) !important;
    --spacing-page-mid-120: calc(var(--spacing-base) * 30) !important;
    --spacing-page-lg: var(--spacing-page-xl) !important;
    --spacing-page-xl: calc(var(--spacing-base) * 35) !important;
    --spacing-page-xxl: calc(var(--spacing-base) * 40) !important;
  }

  h1 {
    font-size: 3rem;
  }

  .view-1 {
    padding: 0 18px !important;
  }


  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }


  * {
    -webkit-overflow-scrolling: touch;
  }
}