@import "../../../../global.module.less";

.compare {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-page-lg) 0 0 0;
  background-color: var(--white);
}

.button {
  margin-top: var(--spacing-32);
}

.compare-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_top {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .title {
      margin-bottom: var(--spacing-24);

      strong {
        color: var(--button-blue-1);
      }
    }

    .description {
      max-width: 462px;

      p {
        max-width: 460px;
        font: var(--Heading-3-400);
        opacity: 0.7;
        display: inline;
      }

      strong {
        color: var(--button-blue-1);
        font: var(--Heading-3-400);
        max-width: 460px;
      }
    }
  }

  &_btm {
    position: relative;
    width: 100%;
    display: flex;
    margin-top: var(--spacing-page-mid);

    .compare-other {
      position: relative;
      width: 60%;
      display: flex;
      border-radius: var(--radius-24);
      margin-left: var(--spacing-24);

      .compare-other-drag-wrap {
        position: absolute;
        width: 176%;
        display: flex;
      }

      .compare-card {
        width: 100%;
      }

      .compare-card:not(:last-child) {
        margin-right: var(--spacing-24);
      }
      .compare-card:last-child {
        margin-right: 60px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .compare {
    width: 100vw;
    align-items: flex-start !important;
    overflow: scroll;
  }

  .compare-wrap_btm {
    width: 200vw !important;
    max-width: unset !important;
    overflow: scroll !important;
    transform: none !important;
    padding: 0 18px 24px 18px !important;
  }

  .compare-other-drag-wrap {
    width: 325% !important;
  }

  .compare-card {
    width: 22% !important;
  }

  .compare-other {
    width: 100% !important;
  }

  .compare-wrap_top {
    flex-direction: column;

    & > p {
      margin-top: 32px;
    }

    p {
      margin-top: 0 !important;
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;

      .button {
        margin-top: 0 !important;
      }
    }
  }
}
