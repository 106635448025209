@import url("../../../../global.module.less");

.highlight {
  display: flex;
  justify-content: center;
  background-color: var(--black-50);
  padding: 0 0 var(--spacing-page-lg) 0;
}

.highlight-wrap {
  display: flex;
  flex-direction: column;

  & > .highlight-card {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: var(--spacing-24);
    background-color: var(--white) !important;
    align-items: center;
    width: 100%;
    height: 500px;
    border-radius: var(--radius-24);
    box-shadow: var(--shadow-3);
    overflow: hidden;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 48px;
      margin-bottom: 24px;

      p {
        max-width: 76%;
        font: var(--Heading-2);
        text-align: center;
        margin-top: var(--spacing-16);

        strong {
          opacity: 0.7;
        }
      }
    }

    .highlight-cover {
      background-image: linear-gradient(
        135deg,
        hsla(0, 0%, 100%, 0.84),
        hsla(0, 0%, 100%, 0.58)
      ) !important;
    }

    .highlight-cover-1 {
      background-color: #4fadff !important;
    }
    .highlight-cover-2 {
      background-color: #20a56e !important;
    }
    .highlight-cover-3 {
      background-color: #d88536 !important;
    }
    .highlight-cover-4 {
      background-color: #574fff !important;
    }
    .highlight-cover-5 {
      background-color: #ff4f4f !important;
    }

    .top {
      width: 50%;
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      p {
        max-width: 64%;
        margin-bottom: var(--spacing-32);
        font: var(--Heading-2) !important;
        transition: var(--transition-easeOut-2);
        color: var(--black-900);
      }
    }

    .color-tab-wrap {
      z-index: 1;
      width: fit-content;
      display: flex;
      justify-content: center;
      margin-bottom: var(--spacing-24);
      padding: var(--spacing-8);
      background-color: rgba(255, 255, 255, 1);
      border-radius: var(--radius-full);

      .color-tab-item {
        margin: 0 4px;
      }

      .color-tab-item {
        width: 24px;
        height: 24px;
        border-radius: var(--radius-full);
        background-color: var(--black-100);
        transition: var(--transition-easeOut-1);

        &:hover {
          transform: scale(1.1);
        }

        &:nth-of-type(1) {
          background-color: #5c8dff;
        }
        &:nth-of-type(2) {
          background-color: #57bd7c;
        }
        &:nth-of-type(3) {
          background-color: #ff954f;
        }
        &:nth-of-type(4) {
          background-color: #9166ff;
        }
        &:nth-of-type(5) {
          background-color: #e55d5d;
        }
      }
    }

    .highlight-cover {
      position: absolute;
      right: 0;
      top: unset;
      width: calc(50% - 40px);
      // right: var(--spacing-24);
      height: calc(100% - var(--spacing-24) - var(--spacing-24));
      overflow: hidden;
      background: #0000001c;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 82%;
      transition: var(--transition-easeOut-2);
      padding: var(--spacing-24);
      border-radius: 12px;
      box-sizing: content-box;

      &_img {
        opacity: 0;
        position: absolute;
        transform: scale3d(0.86, 0.86, 0.86);
        transition: var(--transition-easeOutBack-1);
      }
    }

    .highlight-cover {
      &_img {
        position: absolute;
      }
    }
  }

  &_btm {
    display: flex;
  }

  .highlight-card:not(.highlight-card-color) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 500px;
    border-radius: var(--radius-24);
    box-shadow: var(--shadow-3);
    overflow: hidden;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 48px;
      margin-bottom: 24px;

      p {
        max-width: 76%;
        font: var(--Heading-2);
        text-align: center;
        margin-top: var(--spacing-16);

        strong {
          opacity: 0.7;
        }
      }
    }

    &:nth-of-type(1) {
      background-color: var(--white);
      margin-right: var(--spacing-12);
    }
    &:nth-of-type(2) {
      background-color: var(--black-900);
      color: var(--white);
      margin-left: var(--spacing-12);
      background-color: var(--black-900);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 82%;
    }
  }
}

.highlight-card-mobile {
  position: relative;
  .highlight-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    bottom: -21%;
    position: absolute;
    width: 100%;

    & > div {
      position: absolute;
      width: 100%;
    }
  }
}

.highlight-card-color {
  .highlight-cover {
    display: flex;
    justify-content: center;
    align-items: center;

    .highlight-cover_img {
      width: 80%;
    }
  }
}

.highlight-cover_img-show {
  opacity: 1 !important;
  transform: scale3d(1, 1, 1) !important;
}

.color-tab-item-active-1 {
  box-shadow: 0 0 0 2px #5c8dff, inset 0 0 0 2px #fff;
}

.color-tab-item-active-2 {
  box-shadow: 0 0 0 2px #57bd7c, inset 0 0 0 2px #fff;
}

.color-tab-item-active-3 {
  box-shadow: 0 0 0 2px #ff954f, inset 0 0 0 2px #fff;
}

.color-tab-item-active-4 {
  box-shadow: 0 0 0 2px #9166ff, inset 0 0 0 2px #fff;
}

.color-tab-item-active-5 {
  box-shadow: 0 0 0 2px #e55d5d, inset 0 0 0 2px #fff;
}

@media screen and (max-width: 640px) {
  .highlight {
    padding-bottom: 32px !important;
    margin-top: 18px !important;
  }

  .highlight-wrap {
    align-items: center;
  }

  .highlight-wrap_btm {
    flex-direction: column;
    align-items: center;

    .highlight-card-mobile {
      padding: 0 18px !important;
      width: calc(100vw - 36px) !important;
      height: 30.57rem !important;
      margin-right: 0 !important;
      margin-bottom: 16px !important;

      .highlight-cover {
        height: fit-content !important;
        bottom: 80px !important;
        position: absolute !important;
        width: 110% !important;
      }
    }

    .highlight-card-dark {
      padding: 0 18px !important;
      width: calc(100vw - 36px) !important;
      height: 34.57rem !important;
      margin-left: 0 !important;

      .highlight-cover {
        width: 112% !important;
      }
    }
  }

  .highlight-card-color {
    display: flex;
    height: 500px !important;
    flex-direction: column !important;
    padding: 0 18px !important;
    width: calc(100vw - 36px) !important;
    box-sizing: content-box;

    .top {
      width: 100% !important;
      height: 57%;
      justify-content: center;

      p {
        max-width: 90% !important;
      }
    }

    .highlight-cover {
      border-radius: 0 !important;
    }

    .highlight-cover {
      width: 100% !important;
      height: 44% !important;
      right: unset !important;
      bottom: 0 !important;
      top: 0px;

      .highlight-cover_img {
        width: 70% !important;
      }
    }
  }

  .highlight-card:not(.highlight-card-color) .top p {
    max-width: 90% !important;
  }
}
