@import "../../../../../global.module.less";

.left-card {
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(48, 165, 255, 1) 0%,
    rgba(0, 142, 250, 1) 100%
  );
  box-shadow: 0px 12px 32px rgba(5, 8, 13, 1);
  position: relative;
  padding: calc(var(--spacing-base) * 10) calc(var(--spacing-base) * 10) 0;
  overflow: hidden;
}

.left-card-wrap {
  .left-bg {
    position: absolute;
    width: 660px;
    height: 100%;
    top: 0;
    left: 0;
  }

  .title {
    background: rgba(255, 255, 255, 0.11);
    border-radius: 7px;
    color: rgba(255, 255, 255, 1);
    font-size: 1.14rem;
    width: 88px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    margin-bottom: var(--spacing-16);
    font-family: var(--font-family);
    font-weight: 500;
    position: relative;
    z-index: 2;
  }

  .sub-title {
    margin-bottom: var(--spacing-32);
    font-size: 2.85rem;
    line-height: 2.85rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    z-index: 2;
    position: relative;
  }

  .content-scroll-wrap {
    overflow: hidden;

    .content-scroll {
      display: flex;

      .content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: var(--spacing-24);
        position: relative;
        z-index: 1;
        height: 202px;
        width: 100%;
        flex-shrink: 0;
        align-content: flex-start;

        .article {
          padding: var(--spacing-8);
          height: 60px;
          width: calc(100% / 2 - var(--spacing-8));
          border-radius: 12px;
          margin-right: var(--spacing-8);
          margin-bottom: var(--spacing-8);
          background: rgba(255, 255, 255, 0.08);
          display: flex;
          cursor: pointer;
          transition: 0.25s cubic-bezier(0.33, 1, 0.68, 1);

          &:hover,
          &:active {
            background: rgba(255, 255, 255, 0.2);
          }

          &:active {
            transform: scale(0.94);
          }

          .article-icon {
            width: 44px;
            height: 44px;
            margin-right: var(--spacing-12);
            display: flex;
            flex-shrink: 0;
            box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.04);
            border-radius: 10px;
            justify-content: center;
            align-items: center;

            img {
              border-radius: 10px;
              width: 100%;
              height: 100%;
            }
          }

          .article-info {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .article-name {
              color: rgba(255, 255, 255, 1);
              font-size: 14px;
              font-family: var(--font-family);
              font-weight: 500;
            }

            .article-introduce {
              color: rgba(255, 255, 255, 0.7);
              font-size: 0.929rem;
              font-weight: 400;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .footer {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    align-items: flex-end;

    .change {
      display: flex;

      .left-icon-wrap,
      .right-icon-wrap {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.25s cubic-bezier(0.33, 1, 0.68, 1);
        background: rgba(255, 255, 255, 0.14);
        opacity: 0.8;
        border-radius: 50%;

        .left-icon,
        .right-icon {
          width: 8px;
          height: 8px;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.28);
        }

        &:active {
          background: rgba(255, 255, 255, 0.28);
          transform: scale(0.94);
        }
      }

      .left-icon-wrap {
        margin-right: 16px;
      }
    }
  }
}

.resourceBtn {
  &:active {
    border-color: rgba(75, 176, 252, 1);
    background-color: rgba(75, 176, 252, 1);
  }
}

@media screen and (max-width: 640px) {
  .left-card {
    margin-right: 0;
    padding: 32px 32px 0;
    box-shadow: none;
  }

  .left-card-wrap {
    padding-bottom: 32px !important;

    .left-bg {
      height: auto !important;
      width: 335px !important;
      right: 0 !important;
      left: auto !important;
    }

    .title {
      width: 78px !important;
      height: 30px !important;
      margin-bottom: 10px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px !important;
    }

    .subtitle {
      font-size: 26px !important;
      margin-bottom: 40px !important;
    }

    .content {
      flex-direction: column !important;
      margin-bottom: 40px !important;
      height: 216px !important;

      .article {
        width: calc(100% - var(--spacing-8)) !important;
        align-items: center !important;
        margin-right: 0 !important;
        margin-bottom: 8px !important;
        height: 48px !important;

        &:last-child {
          margin-bottom: 0 !important;
        }

        .article-img {
          width: 40px !important;
          height: 40px !important;
        }
      }
    }

    .footer {
      align-items: center !important;
      margin-bottom: 0 !important;

      .change {
        align-items: baseline;

        .left-icon-wrap {
          margin-right: 16px;
        }
      }
    }
  }
}
