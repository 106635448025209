@import "../../global.module.less";

.wrapper {
  position: relative;
  width: 100%;
  padding: var(--spacing-page-xl) 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--black-50);
  overflow: hidden;
}

.guide-wrap {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.left {
  width: 50%;
  z-index: 1;

  > h2 {
    margin-bottom: 29px;
    font: var(--Heading-0);
    line-height: 48px;

    strong {
      font-size: 24px;
      color: var(--button-blue-1);
    }
  }

  > p {
    margin-bottom: 56px;
    max-width: 380px;
    text-align: left;
    opacity: 0.7;
    font-size: 22px;
  }
}

.right {
  width: 50%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  left: 56%;
  top: 0;
  background-color: var(--black-900);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 82%;

  .bg-follow {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    background-color: var(--oceanblue-50);
  }

  .right_drag_wrap {
    z-index: 1;
    position: absolute;
    width: 800px;
    height: 520px;
    overflow: hidden;
    left: -153.5px;
    border-radius: var(--radius-8);
    box-shadow: var(--shadow-3);
    transform: translateY(60%);

    &:hover {
      .drag-mask {
        transition: none;
      }
    }

    .drag-mask {
      width: 80.8%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      right: 0;
      transition: var(--transition-easeOut-1);
    }

    .guide-img {
      position: absolute;
      width: 800px;
      right: 0;
      pointer-events: none;
    }
  }
}

.btn {
  align-items: flex-start;

  p {
    font-size: 12px;
    opacity: 0.7;
    text-align: center;
  }

  > div {
    width: 100%;
    max-width: 370px;
  }

  > div:first-child {
    > :nth-child(1) {
      position: relative;
      display: flex;
      align-items: center;
      padding: 6px;
      width: 100%;
      height: 60px !important;
      border-radius: var(--radius-12);
      box-shadow: var(--shadow-1);
      transition: var(--transition-easeOut-2);
      background-color: var(--white);
    }
  }

  > p:last-child {
    max-width: 370px;
    padding-inline: 35px;
  }
}

.loginedBtn {
  align-items: flex-start;

  > div:first-child {
    width: 100%;
    max-width: 370px;

    > :first-child {
      height: 60px;
      // width: 100%;
      // margin-top: var(--spacing-12);
      margin-left: 0px;
    }
  }

  > p:last-child {
    max-width: 370px;
    padding-inline: 35px;
    text-align: center;
  }
}

@media screen and (max-width: 640px) {
  .wrapper {
    padding: 48px 0;
  }

  .guide-wrap {
    .left {
      width: 100%;

      // section {
      //   padding-bottom: 40px;
      //   border-bottom: 1px solid rgb(36 48 66 / 14%);
      // }
    }
    .right {
      display: none !important;
    }
  }

  .btn {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      flex-direction: row;
      max-width: unset !important;
    }

    div,
    p {
      padding-inline: 0;
      max-width: initial;
    }

    > p:last-child {
      max-width: 300px;
      padding-inline: initial;
    }

    > div:first-child {
      > :nth-child(1) {
        height: 48px !important;
      }

      > :nth-child(2) {
        height: 48px !important;
        width: 40% !important;
        margin-top: 0px !important;
        margin-left: 8px !important;
      }
    }
  }
}
