@import "../../global.module.less";
/* 隐藏滚动条 */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.home {
  width: 100vw;
  .body {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    // margin-top: 88px;
    // background-color: rgba(255, 255, 255, 0.94);
    background: #f4f8fb;
  }
  .footerContainer {
    width: 100vw;
    font: var(--font-family);
    margin: 0 auto;
    background-color: white;
  }
}

.homeMobile {
  .home();
  .body {
    // margin-top: 0;
  }
}

.container {
  width: 100vw;
  // max-width: var(--view-1);
  margin: -20px 20px 0 20px;
}
