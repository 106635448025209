.panel {
  position: fixed;
  top: 64px;
  right: 36px;
  padding: 16px 0 0 0;
  width: 360px;
  height: 0px;
  overflow: hidden;
  border-radius: 12px;
  opacity: 0;
  color: rgba(32, 32, 32, 1);
  transform: translateY(-10%) scale(0.9);
  transition: opacity 0.15s ease-out 0s, transform 0.15s ease-out 0s;
  box-shadow: 0px 0px 12px rgb(23 25 29 / 6%),
    0px 50px 40px -16px rgb(33 38 48 / 20%);
    background: #fff;
  z-index: -1;
}


.showPanel{
  height: auto;
  min-height: 400px;
  opacity: 1;
  z-index: 999;
  transform: translateY(4px) scale(1);
}