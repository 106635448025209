@import "../../../../../global.module.less";

.team-tab {
  position: relative;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  overflow: hidden;
  transition: var(--transition-easeOut-2);

  &:hover {
    .feature-tab-icon {
      opacity: 1 !important;
    }
  }
}

.team-tab-active {
  width: 125px !important;
  background: var(--button-blue-1);
  border-radius: 1000px;
  margin: 0 !important;

  .feature-tab-wrapper {
    padding: var(--spacing-base) var(--spacing-12) !important;
  }

  .feature-tab-icon {
    opacity: 1 !important;
    filter: invert(1);
  }

  .tab-text {
    opacity: 1 !important;
    transform: translateX(0) !important;
    color: white !important;
  }
}

.feature-tab-wrapper {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  padding: var(--spacing-base) var(--spacing-8);

  .feature-tab-icon {
    width: 28px;
    height: 28px;
    opacity: 0.5;
    transition: var(--transition-easeOut-1);
  }
}

.tab-text {
  font: var(--Paragraph-16);
  font-weight: 500;
  word-break: keep-all;
  margin-left: 4px;
  opacity: 0;
  transform: translateX(-8px);
  transition-delay: 0.4s;
  transition: var(--transition-easeOut-2);
}

@media screen and (max-width: 640px) {
  .feature-tab-icon {
    width: 24px !important;
    height: 24px !important;
  }

  .team-tab-active {
    width: 100px !important;
  }
}
