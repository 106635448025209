@import url("../../../../../global.module.less");

.feature-team {
  position: relative;
  margin-top: var(--spacing-page-mid-120);
  width: 100%;
  max-height: 50vh;
  height: 100vh;
  min-height: 46.85rem;
  background-color: var(--white);
  display: flex;
  padding: 0 !important;
  overflow: hidden;
}

.features_card {
  padding: 4rem;
  border-radius: var(--radius-24);
  box-shadow: var(--shadow-3);

  .card_icon {
    overflow: hidden;
    border-radius: 14px;
    width: 46px;
    height: 46px;
    margin-bottom: var(--spacing-16);
  }

  .left {
    h2 {
      font: var(--Paragraph-1);
      color: #009dff;
      opacity: 1 !important;
      font-weight: 600;
    }

    h3 {
      font: var(--Heading-2);
    }
  }
}

.left {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.57rem;

  h2 {
    color: var(--black-900);
    opacity: 0.6;
    margin-bottom: var(--spacing-8);
  }

  &_mid {
    margin: var(--spacing-8) 0 var(--spacing-page-mid) 0;

    .content-wrap {
      position: relative;
      width: 100%;
      height: 270px;
    }

    .feature-tab-wrap {
      display: flex;

      &_tab-item:not(:last-child) {
        margin-right: 6px;
      }
    }

    .left_mid_content {
      position: absolute;
      transition: var(--transition-easeOut-2);
    }

    .feature-team_subtitle {
      font: var(--Heading-2);
      max-width: 76%;
    }

    .feature-team_des {
      font: var(--Paragraph-1-400);
      opacity: 0.7;
      max-width: 85%;
      margin-top: var(--spacing-page-mid);
    }

    & > i {
      display: block;
      width: 100%;
      height: 1px;
      margin: var(--spacing-32) 0 var(--spacing-24) 0;
      background-color: var(--black-900);
      opacity: 0.14;
    }
  }

  div[data-type="button"] {
    width: 45%;
  }
  .left_btm {
    span {
      transform: translateX(0);
      transition: var(--transition-easeOut-2);
    }
    &:hover {
      span {
        display: inline-block;
        transform: translateX(5px);
      }
    }
  }
}

.right {
  width: 60%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: 165%;
  background-position: top;
  overflow: hidden;
  background-attachment: fixed;

  .card_cover-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card_cover-show {
    opacity: 1 !important;
    transform: scale3d(1, 1, 1) !important;
  }

  .card_cover {
    opacity: 0;
    width: 86%;
    position: absolute;
    border: 1px solid rgba(52, 59, 69, 0.14);
    box-shadow: 0px 8px 16px rgba(8, 23, 69, 0.21);
    overflow: hidden;
    border-radius: var(--radius-12);
    transform: scale3d(0.9, 0.9, 0.9);
    transition: var(--transition-easeOutBack-1);

    video {
      display: flex;
    }
  }
}

@media screen and (max-width: 640px) {
  .feature-team {
    flex-direction: column;
    height: 630px !important;
    min-height: unset !important;
    max-height: unset !important;
  }

  .left_btm {
    display: none;
  }

  .left_mid .content-wrap {
    height: 240px !important;
  }

  .left {
    padding: 32px;
  }

  .left,
  .right {
    width: 100%;
  }

  .right {
    right: unset;
    left: unset;
    bottom: -40px;
    height: 50%;
    width: 100%;
    background-attachment: unset !important;

    .card_cover-wrap {
      margin-top: -40px;
    }
  }

  .feature-team_subtitle {
    max-width: 90% !important;
  }

  .feature-team_des {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 368px) {
  .left_mid .content-wrap {
    height: 270px !important;
  }
  .right {
    bottom: -80px;

    .card_cover-wrap {
      margin-top: -80px;
    }
  }
}
