@import "../../../../../../global.module.less";

.feature-tool_card {
  background-color: white;
  padding: 0 !important;
  overflow: hidden;
}

.features_card {
  padding: 4rem;
  border-radius: var(--radius-24);
  box-shadow: var(--shadow-3);

  .card_icon {
    overflow: hidden;
    border-radius: 14px;
    width: 46px;
    height: 46px;
    margin-bottom: var(--spacing-16);
  }

  .left {
    h2 {
      font: var(--Paragraph-1);
      color: #009dff;
      opacity: 1 !important;
      font-weight: 600;
    }

    h3 {
      font: var(--Heading-2);
    }
  }
}

.mid {
  padding: 4.57rem;

  & > i {
    display: block;
    width: 100%;
    height: 1px;
    margin: var(--spacing-32) 0 var(--spacing-24) 0;
    background-color: var(--black-900);
    opacity: 0.14;
  }

  .content-wrap {
    position: relative;
    width: 100%;
    height: 200px;
    margin: var(--spacing-8) 0 var(--spacing-page-sm) 0;
  }

  .feature-tab-wrap {
    display: flex;

    &_tab-item:not(:last-child) {
      margin-right: 6px;
    }
  }

  .feature-tool_subtitle {
    font: var(--Heading-2);
    max-width: 85%;
  }

  .feature-tool_des {
    font: var(--Paragraph-1-400);
    opacity: 0.8;
    max-width: 86%;
    margin-top: var(--spacing-page-mid);
  }

  .mid_content {
    position: absolute;
    transition: var(--transition-easeOut-2);
    height: 218px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.top {
  width: 100%;
  height: 34.28rem;

  .card_cover-show {
    opacity: 1 !important;
    transform: scale3d(1, 1, 1) !important;
  }

  .card_cover {
    opacity: 0;
    width: 100%;
    position: absolute;
    top: -20px;
    overflow: hidden;
    transform: scale3d(0.9, 0.9, 0.9);
    transition: var(--transition-easeOutBack-1);
  }
}

@media screen and (max-width: 640px) {
  .top {
    height: 260px !important;
  }
  .mid {
    padding: 32px 32px 26px 32px;
    .content-wrap {
      height: 195px !important;
    }

    .mid_content {
      height: 180px !important;
    }
  }

  .feature-tool_subtitle {
    max-width: 90% !important;
  }

  .feature-tool_des {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 368px) {
  .top {
    height: 200px !important;
  }
}
