// @import url("../../../../global.module.less");

.why-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--black-50);
  padding: 120px 0 300px 0;
}

.view-1 {
  display: flex;
  justify-content: center;
}

.right {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 100px;

  .why-us_card {
    position: absolute;
    left: 0;
    width: 100%;
    height: 480px;
    padding: var(--spacing-page-mid);
    box-shadow: var(--shadow-3);
    border-radius: var(--radius-24);
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    &:nth-of-type(1) {
      z-index: 0;
      background: #83dde2;

      .subtitle,
      h6 {
        color: #0b8596;
      }

      .cover {
        background: #eafbfc;
      }
    }
    &:nth-of-type(2) {
      z-index: 1;
      opacity: 0;
      background: #c0a3ff;

      .subtitle,
      h6 {
        color: #5536b1;
      }

      .cover {
        background: #f4f0fe;
      }
    }

    &:nth-of-type(3) {
      z-index: 2;
      opacity: 0;
      box-shadow: var(--shadow-4);
      background: #ef8d87;

      .subtitle,
      h6 {
        color: #92251d;
      }

      .cover {
        background: #fff2f1;
      }
    }

    .card-title {
      font: var(--Heading-2);
      font-size: 40px;
      line-height: 51px;
      max-width: 39%;
      color: var(--button-blue-1);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .subtitle {
        margin-bottom: var(--spacing-page-mid);
        font: var(--Paragraph-1-400);
      }

      h6 {
        opacity: 1;
      }
      strong {
        opacity: 0.6;
      }

      .card-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.57rem;
        height: 4.57rem;
        border-radius: var(--radius-18);
        margin-bottom: var(--spacing-8);
        background: white;
      }
    }

    .cover {
      width: 37%;
      background: white;
      position: absolute;
      top: 40px;
      right: 40px;
      height: 84%;
      border-radius: 16px;
      justify-content: center;
      overflow: hidden;
    }
  }
}

@media screen and (max-height: 900px) {
  .right {
    justify-content: center;
  }

  .why-us_card {
    width: 80%;
    height: 380px;
    padding: var(--spacing-page-sm) !important;
    left: unset !important;

    .cover {
      height: 80% !important;
    }

    .card-title {
      font-size: 32px !important;
      line-height: 1.3 !important;
      .subtitle {
        margin-bottom: 32px !important;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .why-us {
    padding: 180px 0 180px 0 !important;
  }

  .right {
    justify-content: center;
  }
  
  .why-us_card {
    width: calc(100vw - 36px) !important;
    height: 320px !important;
    left: unset !important;
    flex-direction: column;
    padding: 32px !important;

    .card-title {
      max-width: 100% !important;
      font-size: 32px !important;
      line-height: 40px !important;

      .card-icon {
        width: 48px !important;
        height: 48px !important;
      }
    }

    .cover {
      top: unset !important;
      right: unset !important;
      height: auto !important;
      display: none !important;
    }
  }
}
