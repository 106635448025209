@import "../../../../global.module.less";
.LoginInfoBtn {
  position: relative;
  .avatar {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: rgb(117, 110, 214);
    margin-right: 10px;
    text-align: center;
    // line-height: 28px;
    color: white;
    border: 1px solid #f2f2f2;
    font-size: 12px;
    align-items: center;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }

  .text {
    color: rgb(0, 157, 255);
    font: 500 1.142rem/1.71rem var(--font-family);
  }
}
