@import "../../../global.module.less";

.all-center {
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    text-align: center;
  }
}

.title {
  > h1 {
    color: var(--black-900);
    font: var(--Heading-0);
    margin-bottom: var(--spacing-20);
    
    strong {
      color: rgb(157, 164, 175);
    }

    span {
      color: var(--black-700);
    }
  }

  > p {
    font: var(--Heading-3-400);
    max-width: 450px;
    opacity: 0.7;
  }
}

.marginBottomS20 {
  margin-bottom: var(--spacing-20);
}

.fontSize32 {
  font-size: 32px;
}

@media screen and (max-width: 640px) {
  .title {
    width: 100%;
    display: flex;
    flex-direction: column;

    > p {
      font: initial !important;
      font-size: 18px !important;
      max-width: 90% !important;
    }

    > h1 {
      font: initial !important;
    }
  }

  .all-center {
    align-items: center;
    text-align: center;
  }
}
