@import "../../../../global.module.less";

.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--spacing-page-lg);
}

.title {
  h1 {
    display: flex;
    justify-content: center;
  }
}

.features_cards-wrap {
  width: 100%;
  padding: 0 48px;
}



@media screen and (max-width: 640px) {
  .features_cards-wrap {
    padding: 0 18px;
  }
}
