@import url("../../../../global.module.less");

.fourthScreen {
  max-width: var(--maxWidth);
  // width: 90%;
  text-align: center;
  display: flex;
  gap: 22px;
  height: 424px;
  margin-bottom: 20px;
  margin-top: 75px;
  padding: 0px 48px;

  .left,
  .right {
    width: 50%;
    height: 100%;
    border-radius: 1.71428571rem;
    box-shadow: rgba(23, 59, 29, 0.2) 0px 16px 16px -16px,
      rgba(23, 25, 29, 0.03) 0px 14px 20px 0px,
      rgba(23, 25, 29, 0.04) 0px 0px 0px 1px;
    overflow: hidden;
  }

  .left {
    position: relative;
    background-color: white;
    .wrapper {
      padding: 40px 0px 0px 40px;
      h1,
      p {
        text-align: left;
        font-style: normal;
        font-weight: 700;
      }
      h1 {
        color: rgb(0, 157, 255);
        font-size: 17px;
        line-height: 26px;
      }
      p {
        color: rgb(36, 48, 66);
        margin-top: 8px;
        font-size: 30px;
        line-height: 39px;
      }
      .cover {
        border-radius: 12px;
        height: 688px;
        margin-top: 28px;
        box-shadow: rgba(23, 25, 29, 0.34) 0px 11.1px 37px -7.4px,
          rgba(23, 25, 29, 0.03) 0px 7.4px 37px 0px;
      }
    }
  }
  .right {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    @keyframes autoScroll {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-30%);
      }
    }
    .slideshow {
      margin-top: 48px;
      margin-bottom: 20px;
      display: flex;
      height: 162px;
      animation: 20s linear 0s infinite normal none running autoScroll;
      animation-iteration-count: infinite;
      img {
        margin: 0 12px;
        border-radius: 12px;
        height: 100%;
      }
    }
    .title {
      span {
        display: inline-block;
        &:first-child {
          width: 47px;
          text-align: center;
          font-size: 14px;
          line-height: 20px;
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          background-image: linear-gradient(
              106.52deg,
              #15bfff -5.84%,
              #3452ff 101.35%
            ),
            linear-gradient(
              185.48deg,
              rgba(255, 255, 255, 0.23) 4.38%,
              rgba(153, 153, 153, 0.23) 83.28%
            );
          border: 1px solid transparent;
          border-radius: 6px;
          color: white;
          margin-right: 8px;
        }
        &:last-child {
          font-size: 17px;
          color: #009dff;
          font-weight: 656;
          line-height: 26px;
        }
      }
    }
    .slogan {
      color: white;
      text-align: center;
      margin-top: 8px;
      margin-bottom: 24px;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 39px;
    }
    .operation {
      width: 244px;
      .btnText {
        display: flex;
        justify-content: center;
        gap: 8px;
        font-weight: 700;
        font-size: 16px;
        color: #009dff;
      }
    }
    .customBtn {
      &:hover {
        background-color: rgba(255, 255, 255, 0.85);
      }
    }
  }
}

.fourthScreenMobile {
  .fourthScreen();
  flex-direction: column !important;
  margin-top: 32px;
  padding: 0 18px;
  width: initial;
  gap: 18px;
  height: auto;

  .left,
  .right {
    width: 100%;
    height: 424px;
  }
  .left {
    .wrapper {
      padding: 32px;
      h1,
      p {
        width: 110%;
      }
    }
  }
  .right {
    & > div {
      width: 100%;
    }
    .slideshow {
      margin-bottom: 50px !important;
    }
  }
}

.paragraph {
  text-align: center;
  margin-top: calc(var(--spacing-base) * 35);
  margin-bottom: calc(var(--spacing-base) * 30);
  h1 {
    font: bold 4rem/4.64rem var(--font-family);
    color: hsl(216, 30%, 20%);
    margin-bottom: calc(var(--spacing-base) * 5);
    strong {
      color: hsl(216deg 10% 65%);
    }
  }
  p {
    max-width: 505px;
    opacity: 0.7;
    font: 400 1.777rem/2.28rem "PingFang SC", "Microsoft YaHei",
      "Microsoft JhengHei", "黑体", arial, "STHeiti";
  }
}

.secondParagraph {
  // .paragraph();
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // margin-top: 300px;

  .aiBg {
    position: absolute;
    width: 872px;
    // top: -240px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 210px;
    z-index: 1;
    width: 498px;
  }

  .logWrapper {
    display: flex;
    justify-content: center;
    padding: 30px;
  }

  .aiLog {
    width: 60px;
    height: 60px;
    z-index: 1;
    border-radius: 12px;
    box-shadow: rgba(0, 153, 255, 0.37) 12.7939px 4.26461px 67.4295px -2.92969px,
      rgba(0, 153, 255, 0.48) 3.3954px 1.1318px 17.8953px -1.75781px;
    background: rgb(255, 255, 255);
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 29px;

    h1 {
      color: rgb(36, 48, 66);
      line-height: 60px;
      font-size: 52px;
      font-weight: 700;
    }

    strong {
      color: rgb(0, 157, 255) !important;
      font-family: Inter;
    }

    p {
      color: rgb(36, 48, 66);
      text-align: center;
      font-size: 22px;
      font-weight: 400;
      opacity: 0.7;
    }
  }
}

.secondParagraphMobile {
  .secondParagraph();
  // margin-bottom: 10px !important;
  h1 {
    font-size: 35px;
  }
  .aiBg {
    width: 100%;
  }
  .wrapper {
    width: 100%;
    margin-top: 90px;
    padding: 0px 20px;

    .logWrapper {
      padding: 15px 25px;
    }
    .info {
      gap: 16px;

      h1 {
        font-size: 35px;
      }
    }
  }
}
