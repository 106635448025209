@import url("../../../../../global.module.less");

.card-content-notWidget {
  display: block;
  background: none;
  padding: 0;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
  }

  > img {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    max-width: none;
    max-height: none;
  }
}

.card-content-widget {
  display: flex;
  background: rgba(243, 247, 251, 1);
  padding: 17px 25px;

  &::before {
    content: none;
  }

  > img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.content-wrap {
  height: 100%;
  width: 100%;
}

.libResourceBox {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: var(--radius-8);
  overflow: hidden;
}

.thumbBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: #eeeff0;

  > img {
    width: 60%;
  }
}

.firstThumb {
  width: 70%;
  height: 100%;
}

.secondThumb {
  height: calc(50% - 2px);
  margin-bottom: 4px;
}

.thirdThumb {
  height: calc(50% - 2px);
}

.otherThumbBox {
  width: 50%;
  margin-left: 4px;
}

.thumbnailImg {
  pointer-events: none;
  border-radius: 0 !important;
}
