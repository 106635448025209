@import url("../../../../global.module.less");

.hero-motion {
  position: relative;
  display: flex;
  border-radius: var(--radius-24);
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 40px);
  height: calc(100vh - 88px - 24px);
  margin: 0 20px 24px 20px;
  overflow: hidden;
}

.scroll-title {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transform: translateX(25%);
  padding: 7.714rem 5.714rem 5.714rem 5.714rem;

  .view-1 {
    .scroll-title_wrap {
      position: relative;
      left: 5%;
      width: 100%;

      .scroll-title_tab {
        display: flex;
        align-items: center;
        span {
          height: 70px;
          color: var(--white);
          font-weight: 600;
          display: flex;
          align-items: center;
          opacity: 1;
          margin-left: -3.4rem;
          transition: var(--transition-easeOut-2);
          font-family: var(--font-family), "sans-serif";
          font-weight: 700;

          p {
            font-size: 2.8rem;
            line-height: 2.9rem;
            z-index: 99;
          }

          i {
            position: relative;
            top: 3px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: var(--radius-12);
            background: var(--white);
            opacity: 0;
            visibility: hidden;
            transition: var(--transition-easeOutBack-1);
            transform: scale(0.3);

            img {
              width: 64px;
            }
          }
        }

        .gsap-scroll-title_tab-active {
          opacity: 1 !important;
          margin-left: 0.7rem !important;
          padding: 0.7rem 1.7rem 0.9rem 1rem;
          background: white;
          border-radius: 1000px;
          color: var(--black-900) !important;
          justify-content: center;

          i {
            opacity: 1 !important;
            visibility: visible !important;
            transform: scale(1) !important;
          }
        }
      }

      .scroll-title_content-wrap {
        position: relative;
        display: flex;
        align-items: center;
        height: 280px;

        .gsap-scroll-title_content-active {
          opacity: 1 !important;
        }

        .gsap-scroll-title_content {
          opacity: 0;
          position: absolute;
          transition: var(--transition-easeOut-2);
          padding-left: 1rem;

          h3 {
            font: var(--Heading-1);
            margin-bottom: 24px;
            word-break: break-all;
            width: 200%;
          }

          p {
            font: var(--Heading-3-400);
            word-break: break-all;
            opacity: 0.7;
            max-width: 68%;
          }
        }
      }
    }
  }
}

.hero-section-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.slogan {
  z-index: 2;
  position: relative;
  width: 100vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .slogan-wrap {
    z-index: 1;
    position: absolute;

    .slogan-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      // transform: translateY(36px);
    }

    h1 {
      font-size: 4.5rem;
      font-weight: 900;
      margin-bottom: 24px;
      transform: translateY(0);
      font-family: sans-serif;
      font: var(--Heading-0);
    }

    .des {
      transform: translateY(0);
      display: inline-block;
      font: var(--Paragraph-1-400);
      max-width: 600px;
      text-align: center;
      opacity: 0.8;
    }
  }

  .hero-motion-form {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(40px);
  }
}

.mobileTip {
  max-width: 150px;
  background-color: white;
  border-radius: 100px;
  margin-top: 24px;
  margin: 24px auto;
  padding: 6px 12px;
  font: 400 1rem/1.5rem "PingFang SC", "Microsoft YaHei", "Microsoft JhengHei",
    "黑体", arial, "STHeiti", "\5b8b\4f53";
}

.window-wrap {
  width: 57vw;
  height: fit-content;
  overflow: hidden;
  border-radius: var(--radius-8);
  top: 99vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  z-index: 1;
  transform-origin: top;
  transform: translate3d(0px, -30px, -100px);
  box-shadow: 0 50px 100px -20px rgb(50 56 93 / 18%),
    0 30px 60px -30px rgb(0 0 0 / 20%);

  .window {
    position: relative;
    width: 100%;

    .gsap-hero-video-show {
      opacity: 1 !important;
    }

    .gsap-hero-video-hidden {
      opacity: 0 !important;
    }

    .gsap-video-wrap {
      transition: var(--transition-easeOut-2);
      border-radius: var(--radius-12);
      overflow: hidden;
      background: white;

      &:nth-of-type(2) {
        z-index: 1;
      }

      &:nth-of-type(3) {
        z-index: 2;
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        opacity: 0;
        position: absolute;
        top: 0;
      }

      .safari-bar {
        z-index: 1;
        width: 100%;
      }

      video {
        width: 100%;
        margin-top: -2px;
        border-radius: var(--radius-12);
      }
    }
  }
}

.loginedBtn {
  position: relative;
  width: 500px;
  height: 64px;
  padding: 6px;
  justify-content: center;
  display: flex;
  align-items: center;
  // transition: var(--transition-easeOut-2);

  > div:first-child {
    width: 50%;
    height: 100%;
  }
}

@media screen and (max-width: 1180px) {
  .scroll-title_wrap {
    position: unset !important;
    left: unset !important;
  }
}

@media screen and (max-height: 900px) {
  .view-1 {
    padding: 0 !important;
  }

  .scroll-title_wrap {
    span {
      i {
        width: 4rem;
        height: 4rem;
        top: 1px !important;
      }

      p {
        font-size: 2.2rem !important;
        line-height: 2.2rem !important;
      }
    }
  }

  .gsap-scroll-title_tab-active {
    padding: 0.4rem 1rem 0.5rem 0.7rem;
    height: 54px !important;
  }

  .scroll-title_content-wrap {
    height: 200px;

    .gsap-scroll-title_content {
      padding-left: 0 !important;
    }
  }

  .slogan {
    .slogan-wrap {
      h1 {
        font-size: 40px;
        margin-bottom: 16px;
      }

      .des {
        max-width: 440px;
        font: var(--Paragraph-2-400);
      }
    }

    .hero-motion-form {
      margin-top: 36px;
    }
  }

  .window-wrap {
    top: 96vh;
  }
}

@media screen and (max-width: 900px) {
  .slogan {
    .slogan-wrap {
      .des {
        font-size: 1.4rem;
        max-width: 440px;
      }
    }
  }
}

@media screen and (max-height: 700px) {
  .window-wrap {
    top: 102vh;
  }
}

@media screen and (max-width: 640px) {
  .hero-motion {
    width: 100vw !important;
    // height: ${props => 'calc(100vh - ' + props.mobileOffsetHeight + 'px)'} !important;
    margin: 0 0 0 0 !important;
    border-radius: 0 !important;
  }

  .scroll-title {
    padding: 0 12px;
  }

  .window-wrap {
    width: 79vw !important;
    top: 106vh !important;
    border-radius: 4px 4px 5px 5px !important;
  }

  .hero-section-bg {
    background-size: 122% 103% !important;
  }

  .slogan {
    height: 46vh !important;

    h1 {
      font-size: 3.3rem !important;
      margin-bottom: 16px !important;
      font: bold 3.2rem/3.8rem "Noto Sans SC", "-apple-system", "sans-serif" !important;
    }

    .des {
      max-width: 76% !important;
    }

    .info {
      font-size: 0.8rem !important;
      max-width: 76% !important;
    }

    .input-wrapper {
      width: 80% !important;
    }
  }

  .hero-mobile-des {
    padding: 6px 12px;
    background: white;
    border-radius: 100px;
    margin-top: 24px;
  }

  .gsap-video-wrap {
    border-radius: 4px 4px 5px 5px !important;
  }
}
