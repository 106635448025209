.help {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  &:hover {
    .qrCode {
      visibility: visible;
      opacity: 1;
    }
  }
  .qrCode {
    img {
      width: 100%;
    }
    box-shadow: rgba(23, 25, 29, 0.2) 0px 16px 16px -16px,
      rgb(23 25 29/3%) 0px 14px 20px 0px, rgb(23 25 29/4%) 0px 0px 0px 1px;
    transition: opacity 0.2s;
    transform: translateX(-80px);
    bottom: 0;
    padding: 8px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 76px;
    height: 76px;
    border-radius: 8px;
    background-color: white;
  }
  .helpBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
