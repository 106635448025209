.aiModal {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 261px;
  height: 354px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 8px 28px -8px rgba(23, 25, 29, 0.12),
    0px 8px 40px 0px rgba(23, 25, 29, 0.08);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px 0px;
  .tab {
    width: 80px;
    height: 28px;
    padding: 3px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    span {
      transition: background-color 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      img {
        width: 15px;
        height: 15px;
        opacity: 0.5;
      }
    }
    .tabActive {
      transition: background-color 0.2s;
      border-radius: 6px;
      background-color: white;
      border: 1px solid rgba(255, 255, 255, 0.12);
      box-shadow: rgba(23, 28, 39, 0.14) 0px 2px 4px 0px;
      img {
        opacity: 1;
      }
    }
  }
  .tabBody {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    .input {
      resize: none;
      width: 85%;
      height: 100%;
      border: none;
      outline: none;
      caret-color: rgb(0, 157, 255);
      line-height: 22.5px;
    }
  }
  .operation {
    width: 100%;
    padding: 8px;
    border-top: solid 1px rgba(0, 0, 0, 0.08);
    .btnText {
      display: flex;
      justify-content: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 500;
    }
    .customButton {
      &:hover {
        background: #008efa;
        color: white;
      }
    }
  }
}
