@import url("../../../../../global.module.less");

.tab-filter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-16);
  z-index: 1;
}

.dark-tab {
  bottom: -40%;
  padding: 6px;
  width: 100%;

  .tab-item {
    .tab-text {
      color: var(--white) !important;
      opacity: 0.4 !important;
      font-size: 16px;
      line-height: 16px !important;
    }
    .tab-icon {
      filter: invert(0.4) brightness(1.5) !important;
    }

    &.tab-active {
      .tab-icon {
        filter: grayscale(100) brightness(100) !important;
      }

      .tab-text {
        opacity: 1 !important;
      }
    }

    &.no-selected-tab-item {
      &:hover {
        background-color: rgba(255, 255, 255, 0.08) !important;
      }
    }
  }

  .indicator {
    box-shadow: none !important;
  }
}

.tab-icon,
.tab-text {
  z-index: 1;
  font-family: var(--font-family);
  font-weight: 500;
}

.indicator {
  position: absolute;
  left: 0;
  width: 88px;
  height: 65px;
  border-radius: var(--radius-12);
  box-shadow: var(--shadow-2);
  background-color: var(--button-blue-1);
}

.tab-active {
  transition-delay: 0.1s;
  color: var(--white);

  .tab-icon {
    filter: grayscale(100) brightness(100) !important;
  }

  .tab-text {
    color: var(--white) !important;
    opacity: 1 !important;
    font-weight: 400;
  }
}

.tab-item:not(:last-child) {
  margin-right: 1.6%;
}

.tab-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  border-radius: var(--radius-12);
  font: var(--Paragraph-16);
  transition: var(--transition-easeOut-2);
  user-select: none;

  &.no-selected-tab-item {
    &:hover {
      background-color: var(--black-50);
    }
  }

  &:hover {
    .tab-text {
      color: var(--white);
    }
  }

  .tab-text {
    word-break: keep-all;
    opacity: 0.8;
  }

  .tab-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    margin-right: 0;
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 1920px) {
  .tab-item:not(:last-child) {
    margin-right: 1.8% !important;
  }
}

@media screen and (max-width: 1440px) {
  .tab-item:not(:last-child) {
    margin-right: 1.3% !important;
  }
}

@media screen and (max-width: 1300px) {
  .tab-item:not(:last-child) {
    margin-right: 1% !important;
    p {
      font-size: 14px !important;
    }
  }
}

@media screen and (max-width: 1189px) {
  .tab-item:not(:last-child) {
    margin-right: 0.4% !important;
    p {
      font-size: 14px !important;
    }
  }
}
