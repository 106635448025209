@import "../../../global.module.less";

.all-center {
  * {
    text-align: center;
  }
}

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--black-900);

  > p {
    font: initial !important;
    font-size: 18px !important;
    max-width: 90% !important;
    opacity: 0.7;
  }

  > h1 {
    margin-bottom: var(--spacing-20);
    font: initial !important;
    text-align: unset;

    strong {
      color: rgb(157, 164, 175);
    }
  }
}

.all-center {
  align-items: center;
  text-align: center;
}
