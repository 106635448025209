@import "../../../../global.module.less";

.advantage {
  .cover {
    width: 248px;
    height: 116px;
    overflow: hidden;
    border-radius: 8px;
    transition: all 0.2s;
    &:hover {
      transform: scale(1.06);
    }
    img {
      width: 100%;
    }
  }
  .info {
    margin-top: 24px;
    font-size: 13px;
    color: rgb(36, 48, 66);
    line-height: 13px;
    .title {
      opacity: 0.7;
      margin-bottom: 8px;
    }
    a {
      text-align: center;
      min-width: 78px !important;
      height: 44px !important;
      line-height: normal !important;
      &:hover {
        .name {
          color: #0094de;
        }
      }
    }
    .name {
      font-weight: 500;
      font-size: 12px;
    }
  }
}

.designCommunity {
  & > div {
    width: 240px;
    border-radius: calc(var(--radius-base-4) * 2);
  }
  .coverImg {
    height: 134px;
    overflow: hidden;
    box-shadow: rgba(220, 222, 230, 0.7) 0px 0px 0px 1px;
    position: relative;
    &:hover {
      .coverInfo {
        // transform: translateY(32px);
        bottom: -32px;
      }
    }
    img {
      width: 240px;
      height: 100%;
    }
    .coverInfo {
      span {
        display: inline-block;
        margin-right: 10px;
      }
      div {
        margin-right: 8px;
        display: flex;
        align-items: center;
      }
      .status {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: rgb(167, 172, 180);
      }
      transition: all 0.2s ease-in-out 0s;
      position: absolute;
      width: 100%;
      bottom: 0;
      background-color: rgba(34, 43, 70, 0.4);
      backdrop-filter: blur(10px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      height: 32px;
      color: rgb(255, 255, 255);
    }
  }
  .infoCard {
    width: 228px;
    height: 59px;
    margin-top: 16px;
    box-shadow: 0 0 0 1px rgba(220, 222, 230, 0.7);
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color var(--duration-250);
    &:hover {
      background-color: #f3f7fb;
      box-shadow: none;
    }

    .icon {
      text-align: center;
      overflow: hidden;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-shadow: rgb(242, 242, 242) 0px 0px 0px 1px;
      margin-right: 10px;
      img {
        width: 100%;
      }
    }
    .text {
      div {
        &:first-child {
          color: #243042;
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
        }
        &:last-child {
          color: rgba(36, 48, 66, 0.7);
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
}
