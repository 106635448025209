// @import url("../../../../global.module.less");

.workflow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 var(--spacing-page-mid) 0;
  transition: var(--transition-easeOut-2);
  background-color: var(--black-50);

  >.workflow-wrap {
    padding: 0 !important;
  }
}

.title {
  color: var(--white);

  * {
    text-align: left;
  }

  h1 {
    strong {
      color: var(--button-blue-1) !important;
    }
  }

  p {
    // text-align: left;
    opacity: 0.7;
    color: var(--black-900);
  }
}

.workflow-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  .workflow-card {
    display: flex;
    justify-content: space-between;
    height: 600px;
    border-radius: var(--radius-24);
    margin-top: var(--spacing-page-mid);
    box-shadow: var(--shadow-3);
    overflow: hidden;
    max-width: calc(100% - 96px);

    &_left {
      width: 74%;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: var(--white);

      &_top {
        width: 100%;
        height: 52%;
        padding: 4.57rem 80px;

        h3 {
          font: var(--Heading-3-400);
          margin-bottom: var(--spacing-12);
        }

        p {
          font: var(--Paragraph-1-400);
          opacity: 0.7;
          max-width: 580px;
        }
      }
      &_btm {
        position: relative;
        width: 100%;
        height: 48%;

        i {
          z-index: 1;
          position: absolute;
          width: 100%;
          height: 2px;
          background: linear-gradient(
            to right,
            rgb(91 209 216) 0%,
            rgb(30 112 234) 28.6%,
            rgb(166 71 214) 60.83%,
            rgba(235, 73, 73, 1) 100%
          );
        }

        .flow-wrap {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          right: 1%;

          .flow-item {
            position: relative;
            width: calc(85% / 3);
            height: 100%;
            border-right: 2px solid hsl(210deg 30% 91%);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: var(--spacing-24) var(--spacing-16) 0 0;

            p {
              position: absolute;
              top: -36px;
              right: -2px;
              font: var(--Paragraph-16);
            }

            &:nth-of-type(1) {
              p {
                color: rgb(11 152 160);
              }
              span {
                background-color: #83dde2;
              }
            }
            &:nth-of-type(2) {
              p {
                color: #6a43c5;
              }
              span {
                background-color: #c0a3ff;
              }
            }
            &:nth-of-type(3) {
              p {
                color: rgb(202 82 92);
              }
              span {
                background-color: #f8857e;
              }
            }

            span {
              padding: var(--spacing-8) var(--spacing-16);
              font: var(--Paragraph-16);
              background-color: var(--black-100);
              border-radius: var(--radius-full);
              margin-bottom: var(--spacing-8);
              color: hsl(216deg 30% 20% / 86%);
            }
          }
        }
      }
    }
    &_right {
      z-index: 1;
      width: 26%;
      height: 100%;
      background-color: #121a23;
      padding: 4.57rem 32px 32px 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        font: var(--Heading-3-400);
        color: var(--white);
      }

      .chart-wrap {
        display: flex;
        flex-direction: column;

        .chart-item {
          display: flex;
          flex-direction: column;

          &:nth-of-type(2) {
            div {
              width: 75%;
            }
          }
          &:nth-of-type(3) {
            div {
              width: 85%;
            }
          }

          p {
            font: var(--Paragraph-16);
            color: var(--white);
          }

          .chart-line {
            width: 100%;
            display: flex;
            align-items: center;

            div {
              width: 100%;
              height: 4px;
              background: linear-gradient(
                to right,
                rgba(153, 229, 255, 1) 0%,
                rgba(255, 238, 153, 1) 100%
              );
              margin-right: var(--spacing-12);
              border-radius: var(--radius-full);
            }

            span {
              font: var(--Paragraph-2);
              font-weight: 400;
              color: var(--white);
              padding: 3px 6px;
              border-radius: var(--radius-6);
              border: 1px solid var(--white-1);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .workflow-card {
    flex-direction: column;
    width: 100% !important;
    border-radius: 0 !important;
    height: 640px !important;
    padding: 0 !important;
    margin-top: 4rem !important;
    box-shadow: none !important;
    max-width: 100% !important;

    .workflow-card_left_btm {
      .flow-item {
        width: calc(95% / 3) !important;
        border-right: 1px solid hsl(210deg 30% 91%) !important;

        span {
          font-size: 12px !important;
          word-break: break-word;
        }
      }
    }

    .workflow-card_left_top {
      padding: 32px 32px 48px 32px !important;
      height: 60% !important;
    }

    .workflow-card_left {
      width: 100% !important;
    }
    .workflow-card_right {
      width: 100% !important;
      height: auto !important;
      padding: 32px !important;

      h3 {
        margin-bottom: 32px;
      }
    }
  }
}
