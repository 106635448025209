@import "../../../global.module.less";
a {
  text-decoration: none;
  cursor: default;
}

.navContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 88px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  transition: all 0.2s ease-in 0s;
  background-color: rgba(255, 255, 255, 0.94);
  backdrop-filter: saturate(250%) blur(14px) brightness(100%);
  min-width: 993px;
  & > div:first-child {
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;

      i:nth-of-type(1) {
        width: 32px;
        height: 32px;
      }

      i:nth-of-type(2) {
        width: 87px;
        height: 22px;
        margin-left: 8px;
      }
    }
  }
  .navGroupItem {
    display: flex;
    position: relative;
    .navGroupItemParent {
      display: flex;
      align-items: center;
    }
    .navGroupItemBody {
      max-height: 320px;
      display: none;
      justify-content: space-around;
      background-color: white;
      transform: rotateX(-15deg);
      position: absolute;
      top: 60px;
      left: -80px;
      padding: 36px;
      box-shadow: 0 0 12px rgba(23, 25, 29, 0.06),
        0 50px 40px -16px rgba(33, 38, 48, 0.2);
      border-radius: 16px;
      transition: transform 0.4s, opacity 0.4s, width 0.2s, height 0.2s,
        display 0.2s;
    }
    &:hover {
      .navGroupItemBody {
        display: flex;
        opacity: 1;
        transform: rotateX(0);
      }
    }
    .navGroupItemBodyItem {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }
  .navItem {
    min-width: 55px;
    margin-right: 8px;
    padding: 8px 14px;
    cursor: default;
    font-size: 15px;
    font-weight: 400;
    color: #62646a;
    &:hover {
      color: black;
    }
  }

  .nav {
    display: flex;
    a {
      color: #62646a;
      display: inline-block;
      height: 51px;
      line-height: 51px;
      &:hover {
        color: black;
      }
    }
  }

  .operation {
    display: flex;
    align-items: center;
    .notificationsIcon {
      margin-right: 14px;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &:hover:not(.notificationsIconBg) {
        background-color: var(--black-50);
      }

      .spot {
        width: 7px;
        height: 7px;
        border-radius: 3px;
        background-color: #009dff;
        position: absolute;
        top: 4px;
        right: 4px;
      }
    }

    .notificationsIconBg {
      background-color: var(--black-50);
    }

    .login {
      margin-right: var(--spacing-8);
    }
  }
  .navChild {
    width: 324px;
    height: 42px;
    margin-bottom: 40px;
    .navChildContainer {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      transition: transform 0.2s, background-color 0.2s;
      &:hover {
        border-radius: 10px;
        background-color: #f3f7fb;
        transition-delay: 0.01s;
        & > img,
        & > span {
          transform: scale(1.06);
          transition: all 0.2s;
        }
        .rightArrow {
          visibility: visible;
          transform: translateX(0);
          opacity: 1;
        }
      }
      span {
        padding: 3px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        background-color: white;
        border-radius: 0.85714286rem;
        margin-right: calc(var(--spacing-base) * 3);
        box-shadow: rgba(23, 25, 29, 0.2) 0px 16px 16px -16px,
          rgb(23 25 29 / 3%) 0px 14px 20px 0px,
          rgb(23 25 29 / 4%) 0px 0px 0px 1px;
        &:first-child {
          overflow: hidden;
        }
      }
      p {
        margin: 0;
      }
      .title {
        font-size: 16px;
        font-weight: 400;
        color: #243042;
      }
      .description {
        font-size: 13px;
        color: rgba(36, 48, 66, 0.7);
      }
      .rightArrow {
        position: absolute;
        opacity: 0;
        right: 16px;
        transform: translateX(-5px);
        transition: transform var(--duration-250) var(--easeOut);
      }
    }
  }
}
