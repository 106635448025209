@import "../../../../global.module.less";

.signUp-area {
  position: relative;
  width: 100%;
  padding: var(--spacing-page-xl) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black-50);
  overflow: hidden;

  > h2 {
    margin-bottom: 29px;
    font: var(--Heading-0);
    line-height: 48px;

    strong {
      font-size: 24px;
      color: var(--button-blue-1);
    }
  }

  > p {
    margin-bottom: 56px;
    max-width: 550px;
    text-align: center;
    opacity: 0.7;
    font-size: 22px;
  }
}

// @media screen and (max-width: 640px) {
//   //   padding: 48px 0;
// }
